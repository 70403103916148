import React from 'react';
import { t } from 'i18next';
import { Modal, ModalBody, Table, Badge } from 'reactstrap';
import { ApiTaskService } from '../../services/api/task.service';
import { connect } from 'react-redux';
import { TableBody, TableHead } from '../table';
import { Link } from 'react-router-dom';
import { UtilArray } from '../../utils';
import { ButtonActionEdit } from '../buttons';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';
import ModalHeader from './partials/ModalHeader';

class ModalTaskExpired extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            loading: true,
            items: []
        };
    }

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
        document.addEventListener('clientLoggedin', this.load);
    }

    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
        document.removeEventListener('clientLoggedin', () => this.load());
    }

    render() {
        let actions = (
            <Link color="link" to={reverse(routes.communication.tasks.overview)}>
                {t('tools.task:link.overview')}
            </Link>
        );

        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} size="xl" className="modal-appointment container-fluid">
                <ModalHeader title={t('tools.task:modal.expired:title')} actions={actions} onClose={this.onToggle} />

                <ModalBody>
                    <Table size="sm" striped responsive>
                        <TableHead>
                            <tr>
                                <th className="text-center" width="75">{t('tools.task:table:status')}</th>
                                <th className="text-center" width="75">{t('tools.task:table:priority')}</th>
                                <th className="text-nowrap">{t('tools.task:table:title')}</th>
                                <th width="200">{t('tools.task:table:assigned')}</th>
                                <th width="125">{t('tools.task:table:patient')}</th>
                                <th width="125">{t('tools.task:table:due-date')}</th>
                                <th>{t('common:actions')}</th>
                            </tr>
                        </TableHead>
                        <TableBody cols={8}>
                            {this.state.items.map(item => (
                                <tr key={item.id}>
                                    <td className='text-center'>
                                        <Badge pill color={t('tools.task:statuses:colors:' + item.status)}>
                                            {item.statusLabel()}
                                        </Badge>
                                    </td>
                                    <td className='text-center'>
                                        <Badge pill color={t('tools.task:priorities:colors:' + item.priority)}>
                                            {item.priorityLabel()}
                                        </Badge>
                                    </td>
                                    <td>{item.title}</td>
                                    <td>{item.assigned && item.assigned.length > 0 ? item.assigned.join(', ') : '-'}</td>
                                    <td>{item.hasLinkedPatient() ? <Link to={{ pathname: item.patientLink() }}>{item.patient.name}</Link> : '-'}</td>
                                    <td>{item.dateExpiredFormatted()}</td>
                                    <td className='text-right'>
                                        <ButtonActionEdit id={item.id} to={item.editLink()} />
                                    </td>
                                </tr>
                            ))}
                        </TableBody>
                    </Table>
                </ModalBody>
            </Modal >
        );
    }

    onToggle = () => this.setState({ modal: !this.state.modal });
    load = () => ApiTaskService.search({ groupId: this.props.group.id, expired: true }).then(result => this.setState({ items: UtilArray.asort(result, 'dateExpired'), modal: result.length > 0 }));
}
const mapStateToProps = state => ({ group: state.group });

export default connect(mapStateToProps)(ModalTaskExpired);