import React from 'react';
import Spinner from '../reactstrap/Spinner';

class TableRowLoader extends React.Component {
    render() {
        if (!this.props.loading) return null;

        return (
            <tr>
                <td colSpan={this.props.colSpan}>
                    <Spinner visible={true} />
                </td>
            </tr>
        );
    }
}

export default TableRowLoader;
