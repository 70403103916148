import React from 'react';
import { t } from 'i18next';
import UncontrolledTooltip from '../../reactstrap/UncontrolledTooltip';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Button } from 'reactstrap';

class CommunicationField extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedAppointment: null,
            modalOpen: false,
            modalInput: ''
        };
    }

    render() {
        return (
            <div className="communication-field">
                {this.props.data.map((item) => {
                    const hasData = !!(this.props.value[item.appointmentId]);

                    if (this.props.value[item.appointmentId] === undefined) return null;

                    return (
                        <div key={item.appointmentId} className="communication-item">
                            <span className="communication-item__time">{item.start}: </span>
                            {hasData ? (
                                <React.Fragment>
                                    <span className="communication communication-item__title communication-has-data">
                                        {item.name} {' '}
                                        <i className={'fa fa-' + (this.props.field.type === 'communication_sms' ? 'phone' : 'envelope ')} id={'icon-' + item.appointmentId}></i>
                                    </span>

                                    <UncontrolledTooltip target={'icon-' + item.appointmentId}>
                                        {this.props.value[item.appointmentId]}
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            ) : (
                                <span className="communication communication-item__title communication-has-no-data">
                                    {item.name} {' '}
                                    <i className="fa fa-edit" onClick={() => this.setData(item.appointmentId)}></i>
                                </span>
                            )}

                            <i className="fa fa-times" onClick={() => this.removeItem(item.appointmentId)}></i>
                        </div>
                    );
                })}

                {this.renderModal()}
            </div>
        );
    }

    renderModal = () => {
        let patientName = '';
        this.props.data.forEach((data) => {
            if (data.appointmentId !== this.state.selectedAppointment) return;

            patientName = data.name;
        });

        return (
            <Modal isOpen={this.state.modalOpen}>
                <ModalHeader>
                    {t('communication.' + (this.props.field.type === 'communication_sms' ? 'sms' : 'mail') + ':modal.title', { patient: patientName })}
                </ModalHeader>

                <ModalBody>
                    <Input type="text" value={this.state.modalInput} onChange={this.onChangeModalInput} />
                </ModalBody>

                <ModalFooter>
                    <Button color="default" onClick={() => this.handleModalResponse('cancel')}>{t('common:Canel')}</Button>
                    <Button color="info" onClick={() => this.handleModalResponse('confirm')}>{t('common:Confirm')}</Button>
                </ModalFooter>
            </Modal>
        );
    }

    setData = (appointmentId) => this.setState({ selectedAppointment: appointmentId, modalOpen: true });
    onChangeModalInput = (e) => this.setState({ modalInput: e.target.value });

    handleModalResponse = (e) => {
        switch (e) {
            case 'confirm':
                this.onModalConfirm();
                break;
            default:
                break;
        }

        this.setState({
            modalOpen: false,
            selectedPatient: null
        });
    }

    onModalConfirm = () => {
        const { selectedAppointment } = this.state;

        const data = {
            ...this.props.value,
            [selectedAppointment]: this.state.modalInput
        };

        this.props.onChangeValue(this.props.field.name, data);
    }

    removeItem = (appointmentId) => {
        let data = this.props.value;
        delete data[appointmentId];

        this.props.onChangeValue(this.props.field.name, data);
    }
}

export default CommunicationField;