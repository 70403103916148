import React from 'react';
import { Badge } from 'reactstrap';
import { UtilColor } from '../../utils/color.util';
import configureStore from '../../store/store';

class BadgeCategory extends React.Component {
    render() {
        const { schemaData } = configureStore().getState();
        let { id, title, className, bgColor, fontSize, fontWeight, pill } = this.props;
        
        let categories = {};
        if (schemaData) {
            categories = schemaData.categories;
        }
    
        if (id in categories) {
            let category = categories[id];

            bgColor = category.freeSlotBackground;
            title = category.name;
        }

        return (
            <Badge key={Math.random().toString(36).substring(2, 9)} pill={pill} style={{ fontSize: fontSize, fontWeight: fontWeight, backgroundColor: bgColor, color: UtilColor.contrastBlackOrWhite(bgColor) }} className={className}>
                {title}
            </Badge>
        );
    }
}

BadgeCategory.defaultProps = {
    bgColor: '#000000',
    fontSize: '0.75rem',
    fontWeight: '600',
    pill: false
};

export default BadgeCategory;