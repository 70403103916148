import { UtilDevice } from ".";

const bindListeners = (el, callback) => {
    if (UtilDevice.isTouchEnabled()) {
        let contextMenuDayTimer = null;
        el.addEventListener('touchstart', (e) => {
            this.hideContextMenus();
            if (e.target.classList.contains('fc-widget-content')) {
                this.contextMenuDay.hide();
                contextMenuDayTimer = setTimeout(() => callback(e), 500);
            }
        });
        el.addEventListener('touchend', (e) => {
            if (e.target.classList.contains('fc-widget-content')) {
                e.preventDefault();
                if (contextMenuDayTimer) {
                    clearTimeout(contextMenuDayTimer);
                }
            }
        });
    } else {
        el.addEventListener('contextmenu', callback);
    }
}

const position = (e) => {
    let el = document.querySelector('.react-contexify');
        if (!el) return;

        let left = 0;
        let y = 0;
        if (e.detail.clientX) {
            left = e.detail.clientX;
            y = e.detail.clientY;
        } else if (e.clientX) {
            left = e.clientX;
            y = e.clientY;
        } else if (e.layerX) {
            left = e.pageX;
            y = e.pageY;
        } else if (e.touches) {
            left = e.touches[0].clientX;
            y = e.touches[0].clientY;
        } else if (e.changedTouches) {
            left = e.changedTouches[0].clientX;
            y = e.changedTouches[0].clientY;
        }


        let maximumX = document.body.clientWidth - el.getBoundingClientRect().width - 10;
        let maximumY = document.body.clientHeight - el.getBoundingClientRect().height - 10;

        el.style.left = (left < maximumX ? left : maximumX) + 'px';
        el.style.top = (y < maximumY ? y : maximumY) + 'px';
        el.style.opacity = 1;
}

export const UtilContextmenu = {
    bindListeners,
    position
};