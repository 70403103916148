import { API } from '../api';

const getSummary = (appointmentId) => {
    return API.get('client/bingli/bingli/get-summary', { appointmentId: appointmentId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            return response;
        })
};



export const ApiBingliService = {
    getSummary,
};