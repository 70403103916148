import React from 'react';
import { Badge } from 'reactstrap';
import { UtilColor } from '../../utils/color.util';
import configureStore from '../../store/store';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

class BadgeSubcategory extends React.Component {
    render() {
        const { schemaData } = configureStore().getState();
        let { id, title, className, bgColor } = this.props;

        let statuses = {};
        if (schemaData) {
            statuses = schemaData.statuses;
        }

        let fullTitle = title;
        if (id in statuses) {
            let item = statuses[id];

            bgColor = item.backgroundColor;

            title = item.title;
            fullTitle = item.title;
            if (this.props.showCode) {
                title = item.code;
            }
        }

        return (
            <>
                <Badge key={this.props.key} id={'badge_status_' + this.props.key} pill={this.props.pill} style={{ fontSize: this.props.fontSize, fontWeight: this.props.fontWeight, backgroundColor: bgColor, color: UtilColor.contrastBlackOrWhite(bgColor) }} className={className}>
                    {title}
                </Badge>

                {this.props.showTooltip && (
                    <UncontrolledTooltip target={'badge_status_' + this.props.key}>
                        {fullTitle}
                    </UncontrolledTooltip>
                )}
            </>
        );
    }
}

BadgeSubcategory.defaultProps = {
    key: Math.random().toString(36).substring(2, 9),
    bgColor: '#000000',
    fontSize: '0.75rem',
    fontWeight: '600',
    pill: false,
    showCode: false,
    showTooltip: false
};

export default BadgeSubcategory;