import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalHomeVisit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            event: null,
            input: 1
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle}>
                <ModalHeader toggle={this.onToggle}>Aantal huisbezoeken voor slot?</ModalHeader>
                <ModalBody>
                    <input type="number" onChange={this.onChangeInput} value={this.state.input} className="form-control" />
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={this.onConfirm}>{t('common:Confirm')}</Button>
                </ModalFooter>
            </Modal>
        );
    }

    fire = (event) => this.setState({ modal: true, event: event, input: event.extendedProps.appointmentsPerSlot ? event.extendedProps.appointmentsPerSlot : 1 });
    onToggle = () => this.setState({ modal: !this.state.modal });
    onChangeInput = (e) => this.setState({ input: e.target.value });
    onConfirm = () => this.setState({ modal: false }, () => this.props.onConfirm(this.state.event, this.state.input));
}

export default ModalHomeVisit;