import React from 'react'
import { t } from 'i18next';
import { Prompt } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';

export class RouteLeavingGuard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false,
            when: false
        }
    }

    setConfirmedNavigation = (confirmed) => { this.setState({ confirmedNavigation: confirmed }) };
    showModal = (location) => this.setState({ modalVisible: true, lastLocation: location })

    closeModal = (callback) => {
        if ({}.toString.call(callback) === '[object Function]') {
            this.setState({ modalVisible: false }, callback)
        } else {
            this.setState({ modalVisible: false })
        }
    }

    handleBlockedNavigation = (nextLocation) => {
        if (!this.state.confirmedNavigation) {
            this.showModal(nextLocation);
            return false;
        }

        return true;
    }

    handleConfirmNavigationClick = () => this.closeModal(() => {
        if (this.state.lastLocation) {
            this.setState({ confirmedNavigation: true }, () => {
                // Navigate to the previous blocked location with your navigate function     
                this.props.history.push(this.state.lastLocation.pathname);
            });
        }
    })

    componentDidMount() {
        this.props.onRef(this);
        this.setState({ when: true });
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        return (
            <React.Fragment>
                <Prompt when={this.state.when} message={this.handleBlockedNavigation} />

                <Modal isOpen={this.state.modalVisible}>
                    <ModalBody>{t('settings.profile:compose:leavePageConfirmation')}</ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this.closeModal}>{t('common:Cancel')}</Button>
                        <Button color="info" onClick={this.handleConfirmNavigationClick}>{t('common:Confirm')}</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

export default RouteLeavingGuard