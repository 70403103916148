import React from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { ApiAppointmentService } from '../../../../services/api/appointment.service';

class CtpCodeField extends React.Component {
    state = {
        codes: [],
        value: this.props.field.data ? this.props.field.data : '',
        loading: false
    };

    render() {
        const { name, type, readonly } = this.props.field;

        return (
            <>
                <InputGroup>
                    <Input type={type}
                        name={name}
                        id={name}
                        onChange={this.onManualChangeValue}
                        value={this.state.value ? this.state.value : ''}
                        autoComplete="off"
                        readOnly={readonly}
                    />
                    <InputGroupAddon addonType="append">
                        <Button type="button" color="default" onClick={this.searchCtpCode}>
                            <span className="fa fa-search"></span>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
                <span className="hidden text-small text-danger" id={'ctp-message-' + name}></span>
            </>
        )
    }

    onManualChangeValue = (e) => {
        this.setState({ value: e.target.value });
        this.props.onChangeValue(e);
    }

    searchCtpCode = () => this.setState({ codes: [], loading: true }, () => {
        if (!this.props.field.options.codeSeekerField) return;

        this.log(null);

        let nationalInsuranceNumber = null;
        let ninEl = document.getElementById(this.props.field.options.codeSeekerField);
        if (ninEl) nationalInsuranceNumber = ninEl.value;

        if (!nationalInsuranceNumber) {
            this.setState({ loading: false }, () => this.log('Er is geen rijksregisternummer ingegeven.'));
        } else {
            ApiAppointmentService.searchCode('ctp-code', { nationalInsuranceNumber: nationalInsuranceNumber })
                .then(result => {
                    if (result) {
                        if (result.ctpCode) {
                            this.setState({ value: result.ctpCode });
                            this.props.onManualChangeValue(this.props.field.name, result.ctpCode);
                        }

                        if (result.message) {
                            this.log(result.message);
                        }
                    }
                });
        }
    })

    log = (message) => {
        let messageEl = document.getElementById('ctp-message-' + this.props.field.name);
        messageEl.classList.add('hidden');
        messageEl.innerText = message;
        if (message) messageEl.classList.remove('hidden');
    }
}

export default CtpCodeField;