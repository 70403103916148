import React from 'react';
import { t } from 'i18next';
import Select from 'react-select';

class FilterSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectOptionsMulti: []
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        if (this.props.multiple) {
            return (
                <label htmlFor={this.props.id} className="mr-2 w-50">
                    <Select
                        id={this.props.id}
                        simpleValue
                        isMulti
                        placeholder={this.props.placeholder}
                        onChange={this.handleChangeSelectMulti}
                        options={Object.entries(this.props.options).map(([id, title]) => { return { value: id.trim(), label: title } })}
                        isSearchable={true}
                    />
                </label>
            );
        }

        return (
            <label htmlFor={this.props.id} className="mr-2">
                {this.props.label}
                <select id={this.props.id} onChange={this.props.onChange} className="form-control form-control-sm ml-2" defaultValue={this.props.defaultValue ? this.props.defaultValue : null}>
                    <option value="">{t('tools.task:filter-select-all-option')}</option>
                    {Object.keys(this.props.options).map(key => {
                        let { label, disabled } = this.props.options[key];
                        return <option key={key} value={key} disabled={disabled}>{label}</option>
                    })}
                </select>
            </label>
        );
    }

    handleChangeSelectMulti = (selectedOptionMulti) => {
        let selectedOptions = [];
        selectedOptionMulti.forEach((option, i) => {
            selectedOptions.push(option.value);
        });

        this.setState({ selectOptionsMulti: selectedOptions });

        this.props.onChange();
    }
}

export default FilterSelect;