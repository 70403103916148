import React from 'react';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import moment from 'moment';

class BirthDateField extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        let selectedDate = props.field.data ? moment(props.field.data).format('DD/MM/YYYY') : null;

        this.state = {
            date: selectedDate
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.bindChangeListeners();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let { field } = this.props;
        let { date } = this.state;

        let extraProps = {};
        if (date && date.indexOf('/0000') > -1) {
            extraProps.autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');
        }

        return (
            <MaskedInput
                value={date}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                name={field.name}
                id={field.name}
                className="form-control"
                placeholder={'dd/mm/jjjj'}
                guide={true}
                //pipe={autoCorrectedDatePipe}
                keepCharPositions={true}
                onChange={this.handleChange}
                {...extraProps}
            />
        );
    }

    handleChange = (e) => {
        let date = null;
        if (/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/.test(e.target.value)) {
            date = moment(e.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }

        this.props.onChangeValue(this.props.field.name, date);

        this.setState({ date: e.target.value });
    }

    bindChangeListeners = () => {
        document.addEventListener('birthdateChanged', (e) => {
            if (!this.state.date) {
                this.setState({ date: moment(e.detail).format('DD/MM/YYYY') });
            }
        });
    }
}

export default BirthDateField;