import React from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Badge } from 'reactstrap';

class DropdownActions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            selectedItem: {
                label: props.label,
                color: props.color
            }
        };
    }

    render() {
        return (
            <Dropdown group className="d-inline-block mr-md-2" isOpen={this.state.open} toggle={this.toggle}>
                <DropdownToggle caret color={this.state.selectedItem.color} size="sm" className="badge">
                    {this.state.selectedItem.label}
                </DropdownToggle>
                <DropdownMenu>
                    {Object.entries(this.props.options).map(([key, data]) =>
                        <DropdownItem key={key} onClick={() => this.onChange(key, data)}>
                            <Badge pill color={data.color}>{data.label}</Badge>
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>
        );
    }

    toggle = () => this.setState({ open: !this.state.open });
    onChange = (key, data) => this.setState({ selectedItem: data }, () => this.props.onChange(this.props.item.id, { newValue: key }));
};

export default DropdownActions;