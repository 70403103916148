import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { t } from 'i18next';
import moment from 'moment';

class ModalAppointmentMoveClient extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            info: null,
            copy: false,
            client: null,
            options: {
                title: props.title ? props.title : null,
                text: props.text ? props.text : null,
                buttons: [
                    {
                        text: t('common:Cancel'),
                        color: 'default',
                        type: 'cancel'
                    },
                    {
                        text: t('common:Confirm'),
                        color: 'info',
                        type: 'confirm'
                    }
                ]
            }
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        const { open, options } = this.state;

        let modalHeader, modalBody;

        if (options.title) {
            modalHeader = (
                <ModalHeader>{options.title}</ModalHeader>
            );
        }

        if (options.text) {
            modalBody = (
                <ModalBody>{options.text}</ModalBody>
            );
        }

        return (
            <Modal isOpen={open}>
                {modalHeader}
                {modalBody}

                <ModalFooter>
                    {
                        options.buttons.map((btn, i) => {
                            return <Button key={i} color={btn.color} onClick={() => this.handle(btn.type)}>{btn.text}</Button>
                        })
                    }
                </ModalFooter>
            </Modal>
        );
    }

    fire = (info, copy, client) => {
        this.setState({
            info: info,
            copy: copy,
            client: client,
            open: true,
            options: {
                ...this.state.options,
                text: t('agenda.appointment:move_date.message', { date: moment(info.event.start).format('DD/MM/YYYY HH:mm'), interpolation: { escapeValue: false } })
            }
        });
    };

    handle = (e) => {
        switch (e) {
            case 'confirm':
                this.confirm();
                break;
            default:
                this.cancel();
        }

        this.setState({ open: false });
    }

    confirm = () => this.props.moveAppointment(this.state.info, { copy: this.state.copy, client: this.state.client });

    cancel = () => {
        if (this.state.info.revert) {
            this.state.info.revert();
        }
    }
}

export default ModalAppointmentMoveClient;