import React from 'react';
import { t } from 'i18next';
import { Button, Input, InputGroup, InputGroupAddon, Table } from 'reactstrap';
import { ApiAppointmentService } from '../../../../services/api/appointment.service';
import { TableHead, TableBody } from '../../../../components/table';
import { ButtonActionCustom } from '../../../buttons';
import moment from 'moment';

class NineNumberField extends React.Component {
    state = {
        codes: [],
        value: this.props.field.data ? this.props.field.data : '',
        loading: false
    };

    componentDidMount() {
        this.searchNineNumber();
        document.addEventListener('nationalInsuranceNumberInput', this.handleNationalInsuranceNumberInputListener);
    }

    componentWillUnmount() {
        document.removeEventListener('nationalInsuranceNumberInput', this.handleNationalInsuranceNumberInputListener);
    }

    render() {
        return (
            <>
                {this.renderCodesTable()}

                <InputGroup>
                    <Input type={this.props.field.type} name={this.props.field.name} id={this.props.field.name}
                        onChange={this.onManualChangeValue}
                        value={this.state.value ? this.state.value : ''}
                        autoComplete="off"
                        readOnly={this.props.field.readonly}
                    />
                    <InputGroupAddon addonType="append">
                        <Button type="button" color="default" onClick={() => this.searchNineNumber(true)} data-tip="test">
                            <span className="fa fa-search"></span>
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
                <span className="text-small text-muted text-help" style={{ whiteSpace: 'pre-wrap' }} id={'nine-number-help-' + this.props.field.name}></span >
                <span className="hidden text-small text-danger" id={'nine-number-message-' + this.props.field.name}></span>
            </>
        )
    }

    renderCodesTable = () => (
        <Table size="sm" className="mb-1">
            <TableHead>
                <tr>
                    <th width="150">Code</th>
                    <th width="175">Tijdstip</th>
                    <th width="175">Gewenste afname</th>
                    <th className='text-nowrap'>Patient</th>
                    <th className='text-nowrap'>Arts</th>
                    <th width="50">&nbsp;</th>
                </tr>
            </TableHead>
            <TableBody loading={this.state.loading} cols={5}>
                {this.state.codes.map(record => (
                    <tr key={record.code}>
                        <td>{record.code}</td>
                        <td>{moment(record.timestamps.request).format('DD/MM/YYYY HH:mm:ss')}</td>
                        <td>{moment(record.timestamps.collection).format('DD/MM/YYYY HH:mm:ss')}</td>
                        <td>{record.patient.lastName} {record.patient.firstName} - {moment(record.patient.birthDate).format('DD/MM/YYYY')}</td>
                        <td>{record.physician.name} - {record.physician.phone}</td>
                        <td className='text-right'>
                            {record.code === this.state.value ?
                                <ButtonActionCustom id={'select-' + record.code} color="danger" size="xs" icon="minus" tooltip={t('common:Deselect')} onClick={() => this.handleItemDeselect()} /> :
                                <ButtonActionCustom id={'select-' + record.code} size="xs" icon="arrow-right" tooltip={t('common:Select')} onClick={() => this.handleItemSelect(record)} />
                            }
                        </td>
                    </tr>
                ))}
            </TableBody>
        </Table>
    )

    handleItemDeselect = () => this.setState({ value: null }, () => {
        this.props.onManualChangeValue(this.props.field.name, null);
        this.updateMaterialText(undefined);
    });
    handleItemSelect = (record) => this.setState({ value: record.code }, () => {
        this.props.onManualChangeValue(this.props.field.name, record.code);
        this.updateMaterialText(record.info);
    });

    updateMaterialText = (message) => {
        let el = document.getElementById('nine-number-help-' + this.props.field.name);
        if (!el) return;

        el.innerText = message ? message : '';
    }

    onManualChangeValue = (e) => {
        this.setState({ value: e.target.value }, () => {
            let record = this.state.codes.filter(code => '' + code.code === this.state.value)[0];
            if (record) {
                this.handleItemSelect(record);
            } else {
                this.updateMaterialText(undefined);
            }
        });
        this.props.onChangeValue(e);
    }

    searchNineNumber = (manual = false) => this.setState({ loading: true, codes: [] }, () => {
        this.updateMaterialText(undefined);
        if (!this.props.field.options.codeSeekerField) return;

        this.log(null);

        let data = {
            orderId: this.state.value,
            nationalInsuranceNumber: null
        };

        let ninEl = document.getElementById(this.props.field.options.codeSeekerField);
        if (ninEl) data.nationalInsuranceNumber = ninEl.value;

        if (!data.nationalInsuranceNumber && !data.orderId) {
            this.setState({ loading: false });
            if (manual) this.log('Rijksregisternummer of 9 nummer is verplicht.')
        } else {
            ApiAppointmentService.searchCode('nine-number', data)
                .then(result => {
                    if (result.codes && result.codes.length > 0) {
                        this.setState({ codes: result.codes });
                    } else if (result.message) {
                        this.log(result.message);
                    }
                })
                .then(() => this.setState({ loading: false }));
        }
    })

    log = (message) => {
        let messageEl = document.getElementById('nine-number-message-' + this.props.field.name);
        messageEl.classList.add('hidden');
        messageEl.innerText = message;
        if (message) messageEl.classList.remove('hidden');
    }

    _timer = null;
    handleNationalInsuranceNumberInputListener = () => {
        if (this._timer) clearTimeout(this._timer);
        this._timer = setTimeout(() => this.searchNineNumber(), 250);
    };
}

export default NineNumberField;