import React from 'react';
import { t } from 'i18next';
import { Table } from 'reactstrap';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { BadgeTooltip } from '../badge';
import { ButtonActionRemove, ButtonIcon } from '../buttons';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';
import ModalRemove from '../modals/ModalRemove';
import { ApiTaskService } from '../../services/api/task.service';
import moment from 'moment';

class TablePatientTask extends React.Component {
    constructor(props) {
        super(props);

        this.removeTasksModalProps = {
            ...this.props,
            removeUrl: 'client/task/task/remove', // API endpoint URL
            successMessage: t('tools.task:remove.successfull'),
            // redirectUrl: props.match.url,
            actionAfterRemove: this.loadData,
            text: t('tools.task:remove.message'),
            onRef: (ref) => this.modalRemoveTask = ref
        }

        this.state = {
            loading: true,
            patientId: props.patientId,
            tasks: [],
        }
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        return (
            <>
                <ModalRemove {...this.removeTasksModalProps} />
                <Table size="sm" striped bordered responsive>
                    <TableHead>
                        <tr>
                            <th colSpan={this.props.showActions ? 6 : 5} className='text-center'>
                                {t('tools.task:title')}
                            </th>
                        </tr>
                        <tr>
                            <th className="text-center" width="75">{t('tools.task:table:status')}</th>
                            <th className="text-center" width="75">{t('tools.task:table:priority')}</th>
                            <th className="text-nowrap">{t('tools.task:table:title')}</th>
                            <th width="100">{t('tools.task:table:created-date')}</th>
                            <th width="100">{t('tools.task:table:due-date')}</th>
                            {this.props.showActions && <th>{t('common:actions')}</th>}
                        </tr>
                    </TableHead>
                    <TableBody cols={7} loading={this.state.loading}>
                        {this.state.tasks.map(task => {
                            let { id, title, description, priority, status, dateCreated, dateExpired, hasContent } = task;
                            if (status === 'new') return null;

                            if (hasContent && description.length > 500) {
                                description = description.substring(0, 500) + '...';
                            }

                            return (
                                <tr key={id}>
                                    <td className="text-center">
                                        <BadgeTooltip id={'status-' + task.id} pill color={t('tools.task:statuses:colors:' + status)} tooltip={t('tools.task:statuses:text:' + status)}>
                                            {t('tools.task:statuses:text:' + status).substring(0, 1)}
                                        </BadgeTooltip>
                                    </td>
                                    <td className="text-center">
                                        <BadgeTooltip id={'priority-' + id} pill color={t('tools.task:priorities:colors:' + priority)} tooltip={t('tools.task:priorities:text:' + priority)}>
                                            {t('tools.task:priorities:text:' + priority).substring(0, 1)}
                                        </BadgeTooltip>
                                    </td>
                                    <td>
                                        {title}
                                        {hasContent && <span className="icon-info ml-1" data-tip={description}></span>}
                                    </td>
                                    <td>{moment(dateCreated).format('DD/MM/YYYY HH:mm')}</td>
                                    <td>{dateExpired ? moment(dateExpired).format('DD/MM/YYYY HH:mm') : '-'}</td>
                                    {this.props.showActions && (
                                        <td className="table-actions">
                                            <ButtonIcon id={id} size="xs" color="info" icon="pencil-alt" className="mr-1" tooltip={t('common:Edit')} onClick={() => this.onEditClick(id)} />
                                            <ButtonActionRemove size="xs" id={id} modalRemove={this.modalRemoveTask} />
                                        </td>
                                    )}
                                </tr>
                            )
                        })}
                    </TableBody>
                </Table>
            </>
        );
    }

    loadData = () => this.setState({ tasks: [], loading: true }, () =>
        ApiTaskService.getByPatient(this.state.patientId)
            .then(result => {
                if (result) {
                    this.setState({ tasks: result ? result : [], loading: false });
                }
            }));

    onEditClick = (id) => {
        if (this.props.toggleModals) {
            this.props.toggleModals();
        }

        this.props.history.push(reverse(routes.communication.tasks.edit, { id: id }));
    }
}

export default TablePatientTask;