import React from 'react';
import { t } from 'i18next';
import ButtonActionCustom from './ButtonActionCustom';

const ButtonActionRemove = props => <ButtonActionCustom color={props.color} size={props.size} icon="trash" tooltip={t('common:action-remove')} onClick={() => props.modalRemove.fire({ id: props.id })} />

ButtonActionRemove.defaultProps = {
    color: 'danger',
    size: 'sm'
}

export default ButtonActionRemove;