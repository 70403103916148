import React from 'react';
import { BlockPicker } from 'react-color';

class ColorField extends React.Component {
    render() {
        const { name, data } = this.props.field;

        return (
            <BlockPicker
                name={name}
                id={name}
                color={this.props.data[name] ? this.props.data[name] : (data ? data : '#FAEB4E')}
                colors={['#FAEB4E', '#F2C341', '#EDA639', '#E56729', '#E23d21', '#BA345D', '#9C3DBE', '#4F2EB3', '#3769F6', '#57A6D7', '#C8E2B3', '#D8E94B', '#8FF24A', '#FFFFFF', '#000000']}
                triangle={'hide'}
                onChangeComplete={this.onChangeComplete} />
        )
    }

    onChangeComplete = (color) => {
        this.props.onChangeValue(this.props.field.name, color.hex);
    }
}

export default ColorField;