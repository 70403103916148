import React from 'react';
import ReactDOM from 'react-dom';
import { ApiPatientService } from '../services/api/patient.service';
import AutoSuggestItem from '../components/form/AutoSuggestItem';
import { UtilClipboard } from './clipboard.util';

const toClipboard = (appointment) => {

    let text = '';

    if (appointment.appointmentData && appointment.appointmentData.length > 0) {
        appointment.appointmentData.forEach((data) => {
            if (data.value) {
                text += data.label + ': ';
                text += data.value;
                text += '\r\n';
            }
        });
    }

    UtilClipboard.copyTextToClipboard(text);
}

const bindInputListener = (fields, patientDataFields, groupId) => {
    Object.entries(fields).forEach(([fieldId, field]) => {
        let fieldEl = document.getElementById(fieldId);
        if (!fieldEl) return;

        let timeout = null;
        fieldEl.addEventListener('keyup', (e) => {
            if (e.key === 'Escape') return;
            resetAutoSuggest();

            clearTimeout(timeout);
            timeout = setTimeout(() => handleInput(e, fields, patientDataFields, groupId), 250)
        });
        fieldEl.addEventListener('click', () => resetAutoSuggest());
    });
};

const handleInput = (e, fields, patientDataFields, groupId) => {
    let data = { groupId: groupId };

    if (e.target.value.length < 2) {
        return;
    }

    Object.keys(fields).forEach(key => {
        let el = document.getElementById(key);
        if (el) data[fields[key]] = el.value;
    });

    ApiPatientService.search(data)
        .then(results => {
            let autoSuggestEl = document.getElementById('autosuggest-' + e.target.id);
            ReactDOM.render(<AutoSuggestItem items={results} fields={patientDataFields} />, autoSuggestEl);

            let rdtPickerEls = autoSuggestEl.parentElement.getElementsByClassName('rdtPicker');
            [].forEach.call(rdtPickerEls, (el) => {
                el.classList.add('hidden');
            });
        });
};

const resetAutoSuggest = () => {
    [...document.getElementsByClassName('form-autosuggest')].forEach((el) => {
        ReactDOM.render(<AutoSuggestItem items={[]} fields={{}} />, el);

        let rdtPickerEls = el.parentElement.getElementsByClassName('rdtPicker');
        [].forEach.call(rdtPickerEls, (el) => {
            el.classList.remove('hidden');
        });
    });
};

export const UtilAppointment = {
    bindInputListener,
    toClipboard,
    resetAutoSuggest
};