import React from 'react';
import { Badge } from 'reactstrap';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const BadgeIcon = props => (
    <React.Fragment>
        <Badge color={props.color} id={'badge-icon-' + props.id} className={props.className}>
            <i className={"fa fa-" + props.icon} aria-hidden></i>
        </Badge>
        {props.tooltip && (
            <UncontrolledTooltip target={'badge-icon-' + props.id}>
                {props.tooltip}
            </UncontrolledTooltip>
        )}
    </React.Fragment>
)

BadgeIcon.defaultProps = {
    color: 'default'
}

export default BadgeIcon;