import React from 'react';
import { t } from 'i18next';
import 'react-contexify/dist/ReactContexify.min.css';
import routes from '../../routing/routes';
import { reverse } from 'named-urls';
import { UtilClient } from '../../utils/client.util';

class ContextMenuDay extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            forceOpen: false,
            history: props.history,
            client: props.client,
            date: null,
            resourceId: null,
            hasAccessToSettings: UtilClient.hasAccessToSettings()
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        document.addEventListener('click', this.hideMenuListener);
        document.addEventListener('contextmenu', this.hideMenuListener);
        document.addEventListener('touchstart', this.hideMenuListener);
    }
    componentDidUpdate() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }
    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }

        document.removeEventListener('click', this.hideMenuListener);
        document.removeEventListener('contextmenu', this.hideMenuListener);
        document.removeEventListener('touchstart', this.hideMenuListener);
    }

    render() {
        if (!this.state.visible) return null;

        return (
            <div className="react-contexify context-menu context-menu-day">
                <div>
                    {this.state.hasAccessToSettings && (
                        <div className="react-contexify__item" onClick={this.onNewAppointment}>
                            <div className="react-contexify__item__content">{t('agenda.myagenda:contextMenu:newDayAppointment')}</div>
                        </div>
                    )}
                    <div className="react-contexify__item" onClick={this.onNewNote}>
                        <div className="react-contexify__item__content">{t('agenda.myagenda:contextMenu:newNote')}</div>
                    </div>
                </div>
            </div >
        );
    }

    show = (props) => this.setState({ date: props.date, resourceId: props.resourceId, client: props.client, visible: true, forceOpen: true });
    hide = () => this.setState({ date: null, resourceId: null, client: null, visible: false });

    onNewAppointment = () => {
        this.onItemClick();

        this.state.history.push(reverse(routes.dayappointments.add, {
            client: this.state.resourceId ? this.state.resourceId : this.state.client,
            date: this.state.date.format('YYYY-MM-DD')
        }));
    }

    onNewNote = () => {
        this.onItemClick();
        this.state.history.push(reverse(routes.agenda.notes.add, {
            client: this.state.resourceId ? this.state.resourceId : this.state.client,
            start: this.state.date.format('YYYYMMDDHHmm'),
            allDay: 1
        }));
    }

    onItemClick = () => this.hide();

    hideMenuListener = (e) => {
        if (!this.state.visible) return;

        if (this.state.forceOpen) {
            this.setState({ forceOpen: false });
            return;
        }

        let paths = e.path || (e.composedPath && e.composedPath());
        if (!paths || !paths.length) return;

        let outsideRdt = true;
        paths.forEach((path) => {
            if (path === undefined) return;
            if (path && path.classList && path.classList.contains('context-menu-day') && (e.target.name === undefined || e.target.name === this.props.field.name)) {
                outsideRdt = false;
            }
        });

        if (outsideRdt) {
            this.setState({ visible: false, date: null, resourceId: null });
        }
    }
}

export default ContextMenuDay;