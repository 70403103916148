
const fallbackCopyTextToClipboard = (text) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Could not copy to clipboard: ', err);
    }

    document.body.removeChild(textArea);
}
const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        // console.log('Copy to clipboard was successful!');
    }, function (err) {
        console.error('Could not copy to clipboard: ', err);
    });
}

export const UtilClipboard = {
    copyTextToClipboard,
    fallbackCopyTextToClipboard
};