import React from 'react';
import { Card } from 'reactstrap';

const CardStatistic = props => (
    <Card color={props.color} className="border-0">
        <div className="row align-items-center mx-0">
            <div className="col-4 text-center">
                <em className={'icon-' + props.icon + ' fa-3x'}></em>
            </div>
            <div className={'bg-' + props.color + '-light col-8 py-4 rounded-right text-center'}>
                <div className="h3 m-0 text-bold">{props.title}</div>
                <div className="mb-0 text-uppercase">{props.description}</div>
            </div>
        </div>
    </Card>
)

export default CardStatistic;