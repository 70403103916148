import moment from "moment";
import { UtilEvent } from "./event.util";
import { UtilEnv } from "./env.util";
import { reverse } from "named-urls";
import routes from "../routing/routes";

const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
// const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set;

const updateFieldData = (id, field, item) => {
    let el = null;
    if (field === 'info') {
        el = document.getElementById('editor-' + id);
    } else {
        el = document.getElementById(id);
    }

    if (!el || (field !== 'gender' && field !== 'country' && el.type === undefined)) {
        return;
    }

    if (field === 'info') {
        UtilEvent.trigger('editortSetContent', { field: field, id: id, value: item[field] ? item[field] : null });
    } else if (field === 'gender' || field === 'country') {
        UtilEvent.trigger('choiceChanged', { value: item[field] ? item[field] : null, triggerChange: true });
    } else {
        if (field === 'birthdate' && item[field]) {
            item[field] = moment(item[field]).format('DD/MM/YYYY');
        }

        nativeInputValueSetter.call(el, item[field] ? item[field] : '');
    }

    if (typeof (Event) === 'function') {
        el.dispatchEvent(new Event('input', { bubbles: true }));
    } else {
        let event = document.createEvent('Event');
        event.initEvent('input', true, true);
        el.dispatchEvent(event);
    }
};

const updatePatientId = (item) => {
    let patientIdEl = document.getElementById('patientId');
    if (!patientIdEl) return;

    patientIdEl.setAttribute('data-id', item.id);
    patientIdEl.setAttribute('data-firstname', item.firstName);
    patientIdEl.setAttribute('data-lastname', item.lastName);
    patientIdEl.setAttribute('data-tags', item.tags ? item.tags.map(t => t.title).join('|') : null);
    patientIdEl.setAttribute('data-appointment-duration', item.appointmentDuration);

    if (item.blockedReason) {
        patientIdEl.setAttribute('data-block-reason', item.blockedReason);
    }

    if (item.deceased) {
        patientIdEl.setAttribute('data-deceased', item.deceased);
    }

    if (item.pendingPayments && item.pendingPayments.length > 0) {
        patientIdEl.setAttribute('data-pending-payments', item.pendingPayments);
    }

    if (item.status_counters) {
        patientIdEl.setAttribute('data-status-counters', JSON.stringify(item.status_counters));
    }

    nativeInputValueSetter.call(patientIdEl, item['id'] ? item['id'] : '');

    if (typeof (Event) === 'function') {
        patientIdEl.dispatchEvent(new Event('input', { bubbles: true }));
    } else {
        let event = document.createEvent('Event');
        event.initEvent('input', true, true);
        patientIdEl.dispatchEvent(event);
    }
};

const loadEditorConfig = (state, id) => {
    let setup = {
        selector: '#editor-' + id,
        height: state.layout.height ? state.layout.height : 500,
        language: 'nl',
        menubar: false,
        plugins: ['lists'],
        toolbar: 'blocks | bold italic underline | bullist numlist | undo redo',
    };

    if (state.isFullyFeatured) {
        setup.plugins.push('link', 'preview', 'fullscreen', 'code');
        setup.toolbar += '| tagButton linkButton link preview fullscreen code';
        setup.setup = (editor) => {
            if (Object.keys(state.tags).length > 0) {
                renderCustomTagButton(state, editor);
            }
            editor.on('ExecCommand', handleEditorExecCommand);
        }
        setup.link_list = renderCustomLinkList(state.links);
        setup.fullscreen_new_window = true;
    }

    return setup;
}

const renderCustomTagButton = (state, editor) => editor.ui.registry.addMenuButton('tagButton', {
    text: 'Placeholders',
    fetch: (callback) => {
        callback(Object.keys(state.tags).map(tag => {
            return { type: 'menuitem', text: state.tags[tag], value: tag, onAction: () => editor.insertContent(tag) }
        }));
    }
});

const renderCustomLinkList = (links) => {
    if (Object.keys(links).length <= 0) return null;

    let baseUrl = UtilEnv.get('REACT_APP_PATIENT_BASE_URL');

    let items = [];
    if ('group' in links) {
        items.push({ title: 'Groepsoverzicht', value: baseUrl + reverse(routes.e[links['group'].type], { path: links['group'].path }) });
    }
    if ('group-type' in links) {
        items.push({ title: 'Groepsoverzicht type afspraken', value: baseUrl + reverse(routes.e[links['group-type'].type], { path: links['group-type'].path }) });
    }

    if ('custom' in links) {
        let customItems = [];
        links.custom.forEach(link => customItems.push({ title: link.title, value: baseUrl + reverse(routes.e[link.type], { path: link.path }) }));

        items.push({ title: 'Eigen links', menu: customItems });
    }

    return items;
}

const handleEditorExecCommand = (e) => {
    switch (e.command) {
        case 'mceFullScreen':
            setTimeout(() => setFullScreenHeight(), 100);
            break;
        default:
            break;
    }
}

const setFullScreenHeight = () => {
    let el = document.querySelector('.tox-tinymce');
    let headerEl = document.querySelector('.topnavbar-wrapper');

    if (!el || !headerEl) return;
    if (!el.classList.contains('tox-fullscreen')) return;

    let heigt = headerEl.getBoundingClientRect().height;
    let editorHeight = el.getBoundingClientRect().height;

    el.style.top = (heigt + 1) + 'px';
    el.style.height = (editorHeight - heigt) + 'px';
}

const selectScrollToSelected = (prefix) => {
    setTimeout(() => {
        let selectedEl = document.getElementsByClassName(prefix + "__option--is-selected")[0];
        if (selectedEl) {
            selectedEl.scrollIntoView({ block: 'nearest' });
        }
    }, 15);
}

export const UtilForm = {
    updateFieldData,
    updatePatientId,

    loadEditorConfig,
    selectScrollToSelected
};