import { Client } from '../../models';
import { API } from '../api';

const getNotifications = (groupId) => API.get('client/client/client/get-notifications', { groupId: groupId })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data.notifications;
        }

        return {};
    });

const logout = () => API.post('client/client/client/logout');

const updateProfilePhoto = (formData) => API.post('client/client/client/update-images', { data: formData }, { 'Content-Type': 'multipart/form-data' });

const updateSetting = (clientId, key, value) => API.post('client/client/client/update-setting', { data: { clientId: clientId, key: key, value: value } })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data.settings;
        }

        return false;
    });

const getByGroup = (groupId) => API.get('client/client/client/get-by-group', { groupId: groupId })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data.clients.map(client => Client.transform(client));
        }

        throw new Error('error');
    })

const getAgendaLinks = (groupId, clientId) => API.get('client/client/client/get-links', { groupId: groupId, clientId: clientId })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data
        }

        return {};
    });

const doRefreshPage = (type, id) => API.get('client/client/client/refresh-agenda', { type: type, id: id })
    .then(response => {
        API.version(response);
        return API.isSuccess(response) ? response.data : false
    });

const enable2fa = (client, code = null) => API.get('client/client/client/enable-2fa', { client: client, code: code })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data;
        }

        throw response.data[0] ? response.data[0] : null;
    });

const disable2fa = (client, code = null) => API.post('client/client/client/disable-2fa', { data: { client: client, code: code } })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data;
        }

        throw response.data[0] ? response.data[0] : null;
    });

const quickUpdate = (data) => API.post('client/client/client/quick-update', { data: data })
    .then(response => {
        if (API.isSuccess(response)) {
            return response.data;
        }

        throw response.data[0] ? response.data[0] : null;
    });

export const ApiClientService = {
    getAgendaLinks,
    getByGroup,
    getNotifications,
    logout,
    updateProfilePhoto,
    doRefreshPage,
    updateSetting,
    enable2fa,
    disable2fa,
    quickUpdate
};