import React from 'react';
import { Input } from 'reactstrap';

class CheckboxField extends React.Component {
    render() {
        const { name, label, value, readonly } = this.props.field;

        let defaultChecked = this.props.data && this.props.field.name in this.props.data && this.props.data[this.props.field.name];
        
        return (
            <div className="checkbox c-checkbox">
                <label>
                    <Input type="checkbox" value={value ? value : 1} name={name} onChange={this.props.onChangeValue} defaultChecked={defaultChecked ? true : false} disabled={readonly} />
                    <span className="fa fa-check"></span> {label}
                </label>
            </div>
        );
    }
}

export default CheckboxField;