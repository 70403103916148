import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import sortable from 'html5sortable/dist/html5sortable.es.js';
import TranslatableTextField from '../form/Elements/TranslatableTextField';
import { ButtonActionCustom } from '../buttons';

class ModalFormFieldOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            options: {}
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentDidUpdate() {
        sortable('.sortable');
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle}>
                <ModalHeader toggle={this.onToggle}>
                    {t('settings.form:modal:options')}

                    <Button color="success" className="btn-labeled" onClick={this.onAddClick}>
                        <span className="btn-label"><i className="fa fa-plus" aria-hidden></i></span>
                        {t('common:Add')}
                    </Button>
                </ModalHeader>
                <ModalBody>
                    <Table striped bordered responsive>
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>{t('settings.form:modal:option')}</th>
                                <th>{t('common:actions')}</th>
                            </tr>
                        </thead>
                        <tbody id="field-sortable" className="sortable">
                            {this.renderOptionRows()}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={this.onToggle}>{t('common:Cancel')}</Button>
                    <Button color="info" onClick={this.onConfirm}>{t('common:Confirm')}</Button>
                </ModalFooter>
            </Modal>
        );
    }

    renderOptionRows = () => {
        let rows = [];

        Object.values(this.state.options).forEach(option => {
            rows.push(
                <tr key={option.id} data-position={option.position}>
                    <td><em className="fas fa-bars fa-fw text-muted mr-lg"></em></td>
                    <td>
                        <TranslatableTextField key={option.id} onChangeValue={this.onChange} onManualChangeValue={this.onChange} field={{ type: 'text', name: option.position, data: option.value, options: {} }} data={this.state.data} />
                    </td>
                    <td>
                        <ButtonActionCustom color="danger" icon="trash" id={'buttonRemove' + option.id} onClick={() => this.onRemoveClick(option.position)} tooltip={t('common:action-remove')} />
                    </td>
                </tr>
            );
        });

        return rows;
    }

    fire = (data) => {
        let options = {};
        data.forEach(item => options[item.position] = item);

        this.setState({ modal: true, options: options });
    };

    onToggle = () => this.setState({ modal: !this.state.modal });

    onChange = (name, value) => {
        let { options } = this.state;

        Object.entries(options).forEach(([pos, option]) => {
            if (pos.toString() !== name.toString()) {
                return;
            }

            option.value = value;
            options[pos] = option;
        });

        this.setState({ options: options })
    }

    onConfirm = () => this.setState({ modal: false }, () => {
        const items = sortable('.sortable', 'serialize')[0]['items'];

        let options = {};
        items.forEach((item, i) => {
            let option = this.state.options[parseInt(item.node.dataset.position)];
            if (!option) return;

            option.position = i;
            options[i] = option;
        });

        this.props.onConfirm(options);
    });

    onAddClick = () => {
        const keys = Object.keys(this.state.options);

        let newKey = 0;
        if (keys.length > 0) {
            newKey = parseInt(Object.keys(this.state.options)[keys.length - 1]) + 1;
        }

        this.setState({
            options: {
                ...this.state.options,
                [newKey]: {
                    id: null,
                    value: '',
                    position: newKey
                }
            }
        })
    }

    onRemoveClick = (position) => {
        const { options } = this.state;

        delete options[position];
        this.setState({ options: options });
    }
}

export default ModalFormFieldOptions;