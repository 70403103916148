import React from 'react';
import ReactDOM from 'react-dom';
import { t } from 'i18next';
import { Button, Modal, Table } from 'reactstrap';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';
import { ModalBody, ModalFooter, ModalHeader, ModalPatientInfo, ModalPayment } from '.';
import { BadgeSubcategory } from '../badge';
import { ButtonIcon } from '../buttons';
import { ApiTaskService } from '../../services/api/task.service';
import { Task } from '../../models';
import { UtilTask } from '../../utils/task.util';
import ReactToPrint from "react-to-print";
import { UtilCommunication } from '../../utils';

class ModalTask extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.initModal();
    }

    initModal = () => {
        return {
            loading: true,
            item: new Task(),
            modal: true,
            nestedModal: false,
            nestedPaymentModal: false
        };
    }

    componentWillReceiveProps() {
        this.setState(this.initModal());
    }

    componentDidMount() {
        this.props.onRef(this);
        this.load();
    }

    componentDidUpdate() {
        if (!this.state.item.id) {
            this.load();
        }
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        const { item } = this.state;

        let actions = (
            <>
                {item.payment.applicable && (
                    <ButtonIcon icon="euro-sign" className={'float-right mr-1 text-' + t('settings.payment:status:color:' + item.payment.status)} id={'payment-' + item.id} tooltip="Online betaling" onClick={this.onAppointmentPaymentModalClick} />
                )}
                {item.hasLinkedPatient() && (
                    <>
                        <ButtonIcon icon="user" className="float-right mr-1" id={item.patient.id} tooltip={t('patients.patient:info')} onClick={() => this.onPatientDetailClick(item.patient.id)} />
                        {this.renderPatientInfoModal()}
                    </>
                )}
            </>
        );

        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                <ModalHeader actions={actions} onClose={this.onToggle}
                    title={<>{t('tools.task:modal:title')} {item.hasSubcategory() && <BadgeSubcategory className="ml-2" id={item.subcategory.id} title={item.subcategory.name} />}</>}
                />

                <ModalBody loading={this.state.loading}>
                    <Table className="table table-borderless table-responsive">
                        <tbody>
                            {item.data.map(data => (
                                <tr key={data.label}>
                                    <td className="modal-item-data-col"><strong>{data.label}</strong></td>
                                    {['phone', 'mobile'].indexOf(data.type) > -1 ? (
                                        <td>{UtilCommunication.phoneButton(data.value)}</td>
                                    ) : (['email'].indexOf(data.type) > -1 ? (
                                        <td>{UtilCommunication.mailButton({ ...this.props, patient: { id: item.patient.id, email: data.value }, callback: () => this.onToggle() })}</td>
                                    ) : (
                                        <td dangerouslySetInnerHTML={{ __html: data.value }}></td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <ButtonIcon color="link" icon="copy" onClick={UtilTask.toClipboard(item)}>
                        <span className="ml-1">{t('tools.task:modal:button.data.copy')}</span>
                    </ButtonIcon>
                </ModalBody>

                <ModalFooter className="text-center" loading={this.state.loading}>
                    {item.status === 'new' ? (
                        <div className="modal-buttons">
                            <Button color="success" className="btn-labeled btn-approve" onClick={() => this.props.parent.onAction('approve', this.state.item, true)}>
                                <span className="btn-label"><i className={'fa fa-check'} aria-hidden></i></span>
                                <div className="btn-text">{t('common:action-approve')}</div>
                            </Button>

                            <Button color="danger" className="btn-labeled btn-decline" onClick={() => this.props.parent.onAction('decline', this.state.item, true)}>
                                <span className="btn-label"><i className="fa fa-times" aria-hidden></i></span>
                                <div className="btn-text">{t('common:action-decline')}</div>
                            </Button>
                        </div>
                    ) : (
                        <div className="modal-buttons">
                            <ReactToPrint
                                trigger={() => {
                                    return <Button color="default" className="btn-labeled btn-print">
                                        <span className="btn-label"><i className={'fa fa-print'} aria-hidden></i></span>
                                        <div className="btn-text">{t('common:Print')}</div>
                                    </Button>
                                }}
                                content={() => this.componentRef}
                                pageStyle="
                                    @page { size: 80mm 50mm; }
                                    @media all {
                                        .pagebreak { display: none; }
                                    }
                                    @media print {
                                        .pagebreak { page-break-before: always; }
                                    }
                                "
                            />
                            <TaskPrint item={item} ref={el => this.componentRef = el} />

                            <Button color="default" className="btn-labeled btn-edit" onClick={this.onEditClick}>
                                <span className="btn-label"><i className="fa fa-pencil-alt" aria-hidden></i></span>
                                <div className="btn-text">{t('common:Edit')}</div>
                            </Button>

                            {this.props.removeModal && (
                                <Button color="danger" className="btn-labeled btn-delete" onClick={this.onRemoveClick}>
                                    <span className="btn-label"><i className="fa fa-trash" aria-hidden></i></span>
                                    <div className="btn-text">{t('common:Remove')}</div>
                                </Button>
                            )}
                        </div>
                    )}
                </ModalFooter>
            </Modal >
        );
    }

    load = () => ApiTaskService.get(this.props.id).then(result => this.setState({ loading: false, item: result ? result : new Task() }));

    onPatientSmsClick = (id) => this.onToggle(this.props.history.push(reverse(routes.patients.sms, { id: id }) + '?redirect=' + encodeURIComponent(reverse(routes.communication.tasks.overview))));
    onEditClick = () => this.onToggle(this.props.history.push(reverse(routes.communication.tasks.edit, { id: this.state.item.id })));
    onRemoveClick = () => this.onToggle(this.props.removeModal.fire({ id: this.state.item.id }));

    onToggle = (callback = null) => this.setState({ modal: !this.state.modal }, () => callback ? callback : null);

    // Patient info modal
    renderPatientInfoModal = () => <ModalPatientInfo isOpen={this.state.nestedModal} toggle={this.toggleNested} toggleAll={this.toggleAll} patientId={this.state.item.patient.id} {...this.props} onRef={ref => this._modalPatientInfo = ref} />
    onPatientDetailClick = () => this.setState({ nestedModal: true });
    toggleNested = () => this.setState({ nestedModal: !this.state.nestedModal });

    // Payment modal
    onAppointmentPaymentModalClick = () => this.setState({ nestedPaymentModal: true }, () => ReactDOM.render(<ModalPayment type="task" id={this.state.item.id} payment={this.state.item.payment} onToggle={this.toggleNestedPayment} parent={this} />, document.getElementById('modal-section')));
    toggleNestedPayment = (reload) => reload ? this.reload() : null;
    reload = () => this.setState({ loading: true }, () => this.loadData());

    hideAll = () => this.setState({ nestedModal: false, modal: false });
    toggleAll = () => this.setState({ nestedModal: !this.state.nestedModal }, () => this.onToggle());
}

export default ModalTask;


class TaskPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            style: {
                textLarge: {
                    fontSize: '1.6em'
                },
                bold: {
                    fontWeight: 'bold'
                }
            }
        };
    }

    render() {
        let { item } = this.props;

        return (
            <div className="print-source">
                <Table bordered>
                    <tbody style={this.state.style.textLarge}>
                        <tr>
                            <th colSpan={2} className="text-center">
                                {t('tools.task:modal:title')}
                            </th>
                        </tr>
                        <tr>
                            <td>{t('tools.task:table:type')}</td>
                            <td>{item.subcategory.name}</td>
                        </tr>
                        <tr>
                            <td>{t('tools.task:table:priority')}</td>
                            <td>{t('tools.task:priorities:text:' + item.priority)}</td>
                        </tr>
                        <tr>
                            <td>{t('tools.task:table:created-date')}</td>
                            <td>{item.dateCreated.format('DD/MM/YYYY HH:mm')}</td>
                        </tr>
                        <tr>
                            <td>{t('tools.task:table:due-date')}</td>
                            <td>{item.dateExpired ? item.dateExpired.format('DD/MM/YYYY HH:mm') : '-'}</td>
                        </tr>
                        <tr><td colSpan="2">&nbsp;</td></tr>
                        {item.data.map(data => (
                            <tr key={data.label}>
                                <td>{data.label}</td>
                                <td dangerouslySetInnerHTML={{ __html: data.value }}></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}