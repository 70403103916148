import React from 'react';
import { Button } from 'reactstrap';

const ButtonLabel = props => (
    <Button color={props.color} size={props.size} type={props.type}
        onClick={props.onClick}
        className={'btn-labeled ' + props.className}
        disabled={props.disabled}
    >
        <span className="btn-label">
            <i className={'fa fa-' + props.icon} aria-hidden></i>
        </span>
        <span className="btn-text">{props.children}</span>
    </Button>
)

ButtonLabel.defaultProps = {
    type: 'button',
    color: 'default',
    size: 'md',
    disabled: false
}

export default ButtonLabel;