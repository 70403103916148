import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import { UtilAuthentication, UtilCalendar } from '../../utils';
import OpenAppointmentMomentsidebar from '../../components/layout/OpenAppointmentMomentsidebar';

class Base extends React.Component {
    constructor(props) {
        super(props);

        UtilAuthentication.guardLastActivity(props);

        this.state = {
            showOffSidebar: props.group && props.group.settings.openAppointmentMoment && props.group.settings.openAppointmentMoment.active
        }
    }

    render() {
        return (
            <div className="wrapper">
                <Header {...this.props} />

                <Sidebar />

                {this.state.showOffSidebar && UtilCalendar.isCalendarView(this.props.location.pathname) && <OpenAppointmentMomentsidebar />}

                <section className="section-container">
                    {this.props.children}
                </section>

                <Footer />
                <div id="section-modal"></div>

                <div id="section-context-menu">
                    <div id="section-context-menu-calendar"></div>
                    <div id="section-context-menu-day"></div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ client: state.client ? state.client.active : null, loggedInClient: state.client ? state.client.logged_in : null, settings: state.settings, group: state.group });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Base));