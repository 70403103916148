import { CHANGE_PAGE_SETTING, SET_PAGE_SETTING } from './page.actions';

const initialPageSettings = {
    title: '',
    subtitle: ''
};

const pageReducer = (state = initialPageSettings, action) => {
    switch (action.type) {
        case SET_PAGE_SETTING:
            return {
                ...state,
                'title': action.value.title,
                'subtitle': action.value.subtitle,
            };
        case CHANGE_PAGE_SETTING:
            return {
                ...state,
                [action.name]: action.value
            };
        default:
            return state;
    }
}

export default pageReducer;