import { API } from '../api';

const load = (url, params) => {
    return API.get(url, params)
        .then(response => {            
            if (API.isSuccess(response)) {
                return response.data;
            }

            throw response.data;
        });
};

const submit = (url, id, data) => {
    return API.post(url, {id: id, data: data})
        .then(response => {
            if (API.isSuccess(response)) {
                return response;
            }

            throw response.data;
        });
};

export const ApiFormService = {
    load, 
    submit
};