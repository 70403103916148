import { API } from '../api';

const buildWeekScheduleClient = (data) => API.get('client/schedule/schedule/build-week-schedule', data).then(response => API.isSuccess(response) ? response.data : false);

const buildWeekScheduleGroup = (start, view = 'day', groupId, clients = null) => API.get('client/schedule/schedule/build-week-schedule-group', { startDate: start, view: view, groupId: groupId, clients: clients }).then(response => API.isSuccess(response) ? response.data : false);

const buildSchedule = (data = {}) => API.get('client/schedule/schedule/build-schedule', data).then(response => API.isSuccess(response) ? response.data : false);

const buildWeekScheduleClientHomeVisits = (groupId, date, client) => {
    return API.get('client/schedule/schedule/build-week-schedule-home-visits', { date: date, groupId: groupId, for: client })
        .then(
            response => {
                if (response.status === 'success') {
                    return response.data;
                }

                throw response.data;
            });
}

export const ApiAgendaService = {
    buildWeekScheduleClient,
    buildWeekScheduleGroup,
    buildSchedule,
    buildWeekScheduleClientHomeVisits
};