import { toast } from "react-toastify";
import { t } from 'i18next';
import { LocalStorageService } from "../services/local-storage.service";
import swal from "sweetalert";

const toastError = (message) => toast(message ? message : t('common:errorMessage'), { type: toast.TYPE.ERROR, position: toast.POSITION.TOP_RIGHT });
const toastInfo = (message) => toast(message, { type: toast.TYPE.INFO, position: toast.POSITION.TOP_RIGHT });
const toastSuccess = (message) => toast(message, { type: toast.TYPE.SUCCESS, position: toast.POSITION.TOP_RIGHT });
const toastWarning = (message) => toast(message, { type: toast.TYPE.WARNING, position: toast.POSITION.TOP_RIGHT });
const toastDismiss = () => toast.dismiss();

const error = (message) => LocalStorageService.store('FlashMessage', { status: 'error', message: message ? message : t('common:errorMessage') });
const success = (message) => LocalStorageService.store('FlashMessage', { status: 'success', message: message ? message : t('common:saveSuccessMessage') });
const warning = (message) => LocalStorageService.store('FlashMessage', { status: 'warning', message: message });

const popupError = (title, message) => LocalStorageService.store('PopupMessage', { type: 'error', title: title, message: message ? message : t('common:errorMessage') });
const popupSuccess = (title, message) => LocalStorageService.store('PopupMessage', { type: 'success', title: title, message: message ? message : t('common:saveSuccessMessage') });
const popupWarning = (title, message) => LocalStorageService.store('PopupMessage', { type: 'warning', title: title, message: message });

const swalError = (title, message) => swal({ title: title, text: message, type: 'error' });
const swalSuccess = (title, message) => swal({ title: title, text: message, type: 'success' });
const swalWarning = (title, message) => swal({ title: title, text: message, icon: 'warning' });
const sweetalert = (options) => swal(options).then(p => options.callback(p));

export const UtilNotification = {
    toastError,
    toastInfo,
    toastSuccess,
    toastWarning,
    toastDismiss,

    error,
    success,
    warning,

    popupError,
    popupSuccess,
    popupWarning,

    swalError,
    swalSuccess,
    swalWarning,
    sweetalert
};