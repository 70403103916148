import React from 'react';
import { t } from 'i18next';
import { Button, FormFeedback, Modal, ModalBody, ModalFooter } from 'reactstrap';
import ModalHeader from './partials/ModalHeader';
import EditorField from '../form/Elements/EditorField';

class ModalTaskConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.init(props);
    }

    init = (props) => {
        return {
            message: this.getDefaultMessage(props),
            modal: true,
            hasError: false
        }
    }

    componentWillReceiveProps(newProps) {
        this.setState(this.init(newProps));
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                <ModalHeader title={t('patients.patient:modal.registration.reject:title')} onClose={this.onToggle} />

                <ModalBody className={'form-group ' + (this.state.hasError ? 'is-invalid' : '')}>
                    <EditorField fullyFeatured
                        layout={{ height: 300 }}
                        field={{ name: 'reason', data: this.state.message, options: { tags: [] } }}
                        onManualChangeValue={this.onChangeValue}
                        group={this.props.group}
                    />
                    {this.state.hasError && <FormFeedback>{t('tools.task:modal:reason.error_message.required')}</FormFeedback>}
                    <small>{t('patients.patient:modal.registration.reject:help')}</small>
                </ModalBody>

                <ModalFooter className="text-center">
                    <div className="modal-buttons">
                        <Button color="info" className="btn-labeled" onClick={() => this.onConfirmClick(false)}>
                            <span className="btn-label"><i className="fa fa-ban" aria-hidden></i></span>
                            <div className="btn-text">{t('common:Confirm')}</div>
                        </Button>

                        {this.props.showNextBtn && (
                            <Button color="info" className="btn-labeled" onClick={() => this.onConfirmClick(true)}>
                                <span className="btn-label"><i className="fa fa-ban" aria-hidden></i></span>
                                <div className="btn-text">{t('tools.task:modal:button.confirmnext')}</div>
                            </Button>
                        )}
                    </div>
                </ModalFooter>
            </Modal>
        );
    }

    getDefaultMessage = (props) => {
        let subcategories = props.schemaData.subcategories;

        if (!(props.item.subcategory.id in subcategories)) return null;

        switch (props.action) {
            case 'approve':
                return subcategories[props.item.subcategory.id].taskApprovedMailBody;
            case 'decline':
                return subcategories[props.item.subcategory.id].taskRejectedMailBody;
            default:
                return null;
        }
    }

    onChangeValue = (_, value) => this.setState({ message: value.trim() });
    onConfirmClick = (showNext) => this.setState({ hasError: !(!!this.state.message) }, () => {
        if (this.state.hasError) return;
        this.onToggle(this.props.onConfirm(this.props.item.id, this.state.message, showNext));
    });

    onToggle = (callback) => this.setState({ modal: !this.state.modal, hasError: false }, () => callback);

    hide = () => this.setState({ modal: false, hasError: false });
}

export default ModalTaskConfirmation;