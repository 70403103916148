import React from 'react';
import { t } from 'i18next';
import { Button, Input, Modal, ModalBody, ModalFooter, } from 'reactstrap';
import ModalHeader from './partials/ModalHeader';
import { ApiPatientService } from '../../services/api/patient.service';
import { ApiTagService } from '../../services/api/tag.service';
import { UtilNotification } from '../../utils';
import { ModalPatientFilter } from '.';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';

class ModalTagBulk extends React.Component {
    constructor(props) {
        super(props);

        this._modalFilterProps = {
            group: props.group,
            onRef: ref => this._modalFilter = ref,
            onSearchClick: this.handleModalFilterResponse,
            createOnly: true
        }

        this.state = {
            modal: false,
            loading: true,
            tag: null,
            filters: [],
            selectedFilters: []
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        this.loadFilters();
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        if (!this.state.tag) return null;

        return (
            <>
                <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                    <ModalHeader title={t('settings.tags:bulk:modal.title', { tag: this.state.tag.title, interpolation: { escapeValue: false } })} className="border-0" text={t('settings.tags:bulk:modal.text')} onClose={this.onToggle} />

                    <ModalBody className="border-top">
                        <strong>{t('settings.tags:bulk:link.filters')}</strong>

                        <Button color="link" onClick={() => this.setState({ selectedFilters: this.state.filters.map(filter => filter.id) })}>
                            {t('settings.tags:bulk:filters.select_all')}
                        </Button>
                        <Button color="link" onClick={() => this.setState({ selectedFilters: [] })}>
                            {t('settings.tags:bulk:filters.deselect_all')}
                        </Button>
                        <Button color="link" onClick={() => this._modalFilter.show(true, null, 'filter-new')}>
                            {t('settings.tags:bulk:filters.add')}
                        </Button>
                        <div className='card-columns-2 mt-1'>
                            {this.state.filters.length > 0 && this.state.filters.map(filter => {
                                return (
                                    <div key={filter.id} className="checkbox c-checkbox">
                                        <label>
                                            <Input type="checkbox" value="1" disabled={this.state.selectedFilters.indexOf('all') > -1}
                                                checked={this.state.selectedFilters.indexOf(filter.id) > -1}
                                                onChange={() => this.handleFilterSelect(filter.id)}
                                            />
                                            <span className="fa fa-check"></span> {filter.title}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </ModalBody>
                    <ModalFooter className="border-0">
                        <div className="modal-buttons">
                            <Button color="default" className="btn-labeled" disabled={this.state.selectedFilters.length <= 0}
                                onClick={() => this.handleLinkButtonClick({ action: 'link', filters: this.state.selectedFilters })}
                            >
                                <span className="btn-label"><i className="fa fa-link" aria-hidden></i></span>
                                <div className="btn-text">{t('settings.tags:bulk:link')}</div>
                            </Button>

                            <Button color="danger" className="btn-labeled" disabled={this.state.selectedFilters.length <= 0}
                                onClick={() => this.handleLinkButtonClick({ action: 'unlink', filters: this.state.selectedFilters })}
                            >
                                <span className="btn-label"><i className="fa fa-unlink" aria-hidden></i></span>
                                <div className="btn-text">{t('settings.tags:bulk:unlink')}</div>
                            </Button>
                        </div>
                    </ModalFooter>

                    <ModalBody className="border-top pb-0">
                        <p className='mb-2 text-bold'>{t('settings.tags:bulk:link.all')}</p>
                    </ModalBody>
                    <ModalFooter className="border-0">
                        <div className="modal-buttons">
                            <Button color="default" className="btn-labeled" onClick={() => this.handleLinkButtonClick({ action: 'link-all' })}>
                                <span className="btn-label"><i className="fa fa-link" aria-hidden></i></span>
                                <div className="btn-text">{t('settings.tags:bulk:link.all')}</div>
                            </Button>

                            <Button color="danger" className="btn-labeled" onClick={() => this.handleLinkButtonClick({ action: 'unlink-all' })}>
                                <span className="btn-label"><i className="fa fa-unlink" aria-hidden></i></span>
                                <div className="btn-text">{t('settings.tags:bulk:unlink.all')}</div>
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal >
                <ModalPatientFilter {...this._modalFilterProps} />
            </>
        );
    }

    onToggle = () => this.show(this.state.tag, !this.state.modal);
    show = (tag, doShow = true) => this.setState({ tag: tag, modal: doShow });

    loadFilters = () => this.setState({ loading: true }, () =>
        ApiPatientService.getFilters(this.props.group)
            .then(result => this.setState({ filters: result, loading: false })));

    handleFilterSelect = (filter) => {
        let selectedFilters = this.state.selectedFilters;
        let currentIndex = selectedFilters.indexOf(filter);

        if (currentIndex > -1) {
            selectedFilters.splice(currentIndex, 1);
        } else {
            selectedFilters.push(filter)
        }

        this.setState({ selectedFilters: selectedFilters });
    }

    handleLinkButtonClick = (data) => ApiTagService.bulk(this.state.tag.id, data)
        .then(result => {
            if (result) {
                this.setState({ modal: false, tag: null, selectedFilters: [] }, () => {
                    UtilNotification.toastSuccess('success');
                    this.props.parent.loadItems();
                });
            } else {
                UtilNotification.toastError(t('common:errorMessage'));
            }
        });

    handleModalFilterResponse = (data) => this.props.history.push(reverse(routes.settings.tags.overview) + '?bulk=' + this.state.tag.id);
}

export default ModalTagBulk;