import React, { Component } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import BadgeCustom from '../badge/BadgeCustom';
import { UtilForm } from '../../utils/form.util';

class AutoSuggestItem extends Component {
    constructor(props) {
        super(props);

        this._reset = false;

        this.state = {
            items: props.items
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.items.length === nextProps.items.length && this.state.items.length === nextState.items.length) {
            return false;
        }
        return true;
    }

    componentDidUpdate() {
        if (!this._reset) {
            this.setState({ items: this.props.items })
        }

        this._reset = false;
    }

    render() {
        if (this.state.items.length <= 0) return null;

        return (
            <ul>
                {this.state.items.map((item, i) => (
                    <li key={i} onClick={() => this.handleItemClick(item)} onKeyUp={(event) => this.handleItemKeyUp(event, item)} tabIndex="0">
                        {item.blocked && <span className="mr-2 fa fa-ban"></span>}
                        {item.deceased && <span className="mr-2 fa fa-cross"></span>}
                        {item.firstName} {item.nickname ? '(' + item.nickname + ')' : ''} {item.lastName} ({moment(item.birthDate).format('DD/MM/YYYY')})

                        {this.renderStatusBadges(item)}
                    </li>
                ))}
                <li className="disabled">{t('patients.patient:autosuggest.more-results')}</li>
            </ul>
        );
    }

    renderStatusBadges = (item) => item.status_counters.map(counter => <BadgeCustom key={counter.code} color={counter.bgColor} className="ml-2">{counter.code}: {counter.count}</BadgeCustom>)

    handleItemKeyUp = (event, item) => {
        if (event.keyCode === 13) {
            this.handleItemClick(item);
        }
    }

    handleItemClick = (item) => {
        UtilForm.updatePatientId(item);

        Object.entries(this.props.fields).forEach(([id, field]) => {
            UtilForm.updateFieldData(id, field, item);
        });

        this._reset = true;

        this.setState({ items: [] });
    }
}

export default AutoSuggestItem;