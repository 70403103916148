import React from 'react';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const Icon = props => (
    <>
        <span className={props.icon + ' ' + props.className} id={'icon-' + props.id} aria-hidden></span>

        {props.tooltip && (
            <UncontrolledTooltip target={'icon-' + props.id}>
                {props.tooltip}
            </UncontrolledTooltip>
        )}
    </>
);

export default Icon;