import React from 'react';
import { Input, Label } from 'reactstrap';

class TextField extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            [props.field.name]: {
                first: '',
                second: ''
            }
        }
    }

    handleOnChange = (e) => {
        const { field } = this.props;
        const input = e.target;

        let data = this.state[field.name];
        data[input.id] = input.value;


        this.setState({
            [field.name]: data
        });

        this.props.onChangeValue(field.name, data);
    }

    render() {
        const { name, type, data, required, options, readonly } = this.props.field;

        return (
            <React.Fragment>
                <Label className="col-form-label" for={options.repeated.first.name}>{options.repeated.first.options.label} {required ? '*' : ''}</Label>
                <Input type={options.type ? options.type : type}
                    name={options.repeated.first.name}
                    id={options.repeated.first.name}
                    onChange={this.handleOnChange}
                    defaultValue={data ? data : ''}
                    readOnly={readonly} />

                <Label className="col-form-label" for={options.repeated.second.name}>{options.repeated.second.options.label} {required ? '*' : ''}</Label>
                <Input type={options.type ? options.type : type}
                    name={name + '[' + options.repeated.second.name + ']'}
                    id={options.repeated.second.name}
                    onChange={this.handleOnChange}
                    defaultValue={data ? data : ''}
                    readOnly={readonly} />
            </React.Fragment>
        );
    }
}

export default TextField;