import React from 'react';
import { ModalBody as Body } from 'reactstrap';
import { Loader } from '../../layout';

const ModalBody = props => (
    <Body>
        {props.loading && <Loader show={true} />}
        {!props.loading && props.children}
    </Body>
)

export default ModalBody;