import TableBody from './TableBody';
import TableHead from './TableHead';
import TablePatientStatuses from './TablePatientStatuses';
import TablePatientTask from './TablePatientTask';
import TableRowLoader from './TableRowLoader';
import TableRowNoResult from './TableRowNoResult';

export {
    TableBody,
    TableHead,
    TablePatientStatuses,
    TablePatientTask,
    TableRowLoader,
    TableRowNoResult
}