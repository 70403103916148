import React from 'react';
import { Input } from 'reactstrap';

class TextareaField extends React.Component {
    state = {
        value: this.props.field.data ? this.props.field.data : ''
    };

    componentDidMount() {
        this.addTagListener(this.props.field);
    }

    render() {
        return (
            <Input type={this.props.field.type}
                name={this.props.field.name}
                id={this.props.field.name}
                onChange={this.onChangeValue}
                defaultValue={this.state.value ? this.state.value : ''}
                readOnly={this.props.field.readonly}
                rows={5}
            />
        );
    }

    onChangeValue = (e) => {
        this.setState({ value: e.target.value });
        this.props.onChangeValue(e);
    }

    addTagListener = (field) => {
        if (!field.options.tags || field.options.tags <= 0) return;

        document.addEventListener('tagInsert' + field.name, (e) => {
            let newValue = (this.state.value + ' ' + e.detail.tag).trim();

            this.setState({ value: newValue }, () => {
                this.props.onManualChangeValue(field.name, newValue);

                let input = document.getElementById(field.name);
                input.value = newValue;
                input.focus();
            });
        })
    }
}

export default TextareaField;