import { API } from '../api';

const get = (id) => API.get('client/payment/link/get', { id: id }).then(response => API.isSuccess(response) ? response.data.link : null);
const getByAppointment = (id) => API.get('client/payment/link/get-by-appointment', { id: id }).then(response => API.isSuccess(response) ? response.data : []);
const getByType = (type, id) => API.get('client/payment/link/get-by-type', { type: type, id: id }).then(response => API.isSuccess(response) ? response.data : []);
const getByGroup = (groupId) => API.get('client/payment/link/get-by-group', { id: groupId }).then(response => API.isSuccess(response) ? response.data.links : []);
const send = (data) => API.post('client/payment/link/send', { data: data })
    .then(response => {
        if (API.isSuccess(response)) {
            return true;
        }

        throw response.data ? response.data.join(', ') : null;
    });

export const ApiPaymentLinkService = {
    get,
    getByAppointment,
    getByType,
    getByGroup,
    send
};