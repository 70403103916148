import { API } from '../api';

const getById = (id) => API.get('client/openappointmentmoment/schedule/get', { id: id }).then(response => API.isSuccess(response) ? response.data.schedule : null);
const getByGroup = (id = null) => API.get('client/openappointmentmoment/schedule/get-by-group', { group: id }).then(response => API.isSuccess(response) ? response.data.schedules : []);
const composeSlots = (scheduleId, data) => API.post('client/openappointmentmoment/schedule/slot/compose', { schedule: scheduleId, data: data }).then(response => API.isSuccess(response));
const clone = (scheduleId) => API.get('client/openappointmentmoment/schedule/duplicate-schedule', { id: scheduleId }).then(response => API.isSuccess(response));
const buildSchedule = (params) => API.get('client/openappointmentmoment/schedule/build-schedule', params).then(response => API.isSuccess(response) ? response.data.schema : []);

export const ApiOpenAppointmentMomentService = {
    getById,
    getByGroup,
    composeSlots,

    buildSchedule,
    clone
};