import application from './application/application.middleware';
import client from './client/client.middleware';
import group from './group/group.middleware';
import notifications from './notifications/notifications.middleware';
import page from './page/page.middleware';
import patient from './patient/patient.middleware';
import schemaData from './schema-data/schema-data.middleware';
import settings from './settings/settings.middleware';

export default [
    application,
    client,
    group,
    notifications,
    page,
    patient,
    schemaData,
    settings
]