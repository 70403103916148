export const CHANGE_PAGE_SETTING = 'CHANGE_PAGE_SETTING';
export const SET_PAGE_SETTING = 'SET_PAGE_SETTING';

/**
 * Change a setting value
 * payload.name: name of the setting prop to change
 * payload.value: new value to apply
 */
export function changePageSetting(name, value) {
    return { type: CHANGE_PAGE_SETTING, name, value };
}

export function setPageSettings(value) {
    return { type: SET_PAGE_SETTING, value };
}