import React from 'react';
import { t } from 'i18next';
import { Button } from 'reactstrap';
import ModalFormFieldOptions from '../../modals/ModalFormFieldOptions';

class CollectionModalField extends React.Component {
    constructor(props) {
        super(props);

        this._modalOptions = null;

        this.state = {
            options: props.field.data
        }
    }

    render() {
        return (
            <React.Fragment>
                <Button color="success" onClick={this.onOptionsClick}>{t('settings.form:manageOptions')}</Button>
                <ModalFormFieldOptions onRef={ref => this._modalOptions = ref} onConfirm={this.onModalConfirm} />
            </React.Fragment>
        );
    }

    onOptionsClick = () => {
        if (this._modalOptions) {
            this._modalOptions.fire(this.state.options);
        }
    }

    onModalConfirm = (options) => {
        this.props.onChangeValue('options', options);

        let newOptions = [];
        Object.entries(options).forEach(([position, value]) => {
            newOptions.push(value);
        });

        this.setState({ options: newOptions });
    }
}

export default CollectionModalField;