import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';

const ButtonSave = props => (
    <Button color={props.color} type={props.type} size={props.size}
        onClick={props.onClick ? props.onClick : onClick}
        className={'btn-labeled btn-save ' + props.className}
        disabled={props.disabled}
        name={props.name}
    >
        <span className="btn-label">
            <i className={'fa fa-' + (props.disabled ? 'circle-notch fa-spin' : props.icon)} aria-hidden></i>
        </span>
        {props.children ? props.children : t('common:Save')}
    </Button>
)

ButtonSave.defaultProps = {
    type: 'button',
    color: 'primary',
    icon: 'save',
    size: 'md',
    name: 'save',
    disabled: false
}

const onClick = (e) => {
    let elType = e.target.getAttribute('type');

    if (elType !== 'submit') {
        const formEl = document.getElementById('form-api');
        if (!formEl) return;

        const submitEl = formEl.querySelector('button[type="submit"]');
        if (!submitEl) return;

        submitEl.click();
    }
}

export default ButtonSave;