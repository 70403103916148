import React, { Component } from 'react';
import { t } from 'i18next';
import _ from 'lodash';
import $ from 'jquery';

// Datatables
require('datatables.net-bs')
require('datatables.net-bs4/js/dataTables.bootstrap4.js')
require('datatables.net-bs4/css/dataTables.bootstrap4.css')
require('datatables.net-buttons')
require('datatables.net-buttons-bs')
require('datatables.net-responsive')
require('datatables.net-rowreorder-bs')
require('datatables.net-responsive-bs')
require('datatables.net-responsive-bs/css/responsive.bootstrap.css')
require('datatables.net-plugins/sorting/date-euro')
require('datatables.net-buttons/js/buttons.colVis.js') // Column visibility
require('datatables.net-buttons/js/buttons.html5.js') // HTML 5 file export
require('datatables.net-buttons/js/buttons.flash.js') // Flash file export
require('datatables.net-buttons/js/buttons.print.js') // Print view button
require('datatables.net-keytable');
require('datatables.net-keytable-bs/css/keyTable.bootstrap.css')
require('jszip/dist/jszip.js');
require('pdfmake/build/pdfmake.js');
require('pdfmake/build/vfs_fonts.js');

/**
 * Wrapper component for dataTable plugin
 * Only DOM child elements, componets are not supported (e.g. <Table>)
 */
class Datatable extends Component {

    constructor(props) {
        super(props);

        this._element = null

        const datatableOptions = {
            destroy: true, //zorgt voor een verse instantie
            stateSave: false, //zorgt ervoor dat de laatst bekende status terug ingeladen wordt
            stateDuration: -1, //-1 = session, 0 of hoger = localstorage voor x aantal seconden
            render: true,
            paging: true,
            ordering: true,
            info: true,
            search: true,            
            responsive: true,
            iDisplayLength: 25,
            lengthMenu: [[10, 25, 50], [10, 25, 50]],
            oLanguage: {
                sSearch: '<em class="fa fa-search"></em>',
                sLengthMenu: t('datatables:records.per.page'),
                sZeroRecords: t('datatables:zero.records'),
                sInfo: t('datatables:page.number'),
                sInfoEmpty: t('datatables:no.records'),
                sInfoFiltered: t('datatables:filtered.from'),
                sProcessing: t('datatables:loading'),
                oPaginate: {
                    sNext: '<em class="fa fa-caret-right"></em>',
                    sPrevious: '<em class="fa fa-caret-left"></em>'
                }
            }
        };

        //default options, optioneel overschreven door props.options indien meegegeven, _.merge() gebruikt om ook diepere niveaus te mergen
        this.state = {
            options: _.merge(datatableOptions, this.props.options)
        };
    }

    componentDidMount() {
        this._element = $(this.tableElement).DataTable(this.state.options);

        if (this.props.onRef) {
            this.props.onRef(this._element);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    setRef = node => this.tableElement = node;

    render() {
        return (
            React.cloneElement(React.Children.only(this.props.children), {
                ref: this.setRef
            })
        )
    }
}

export default Datatable;