import { reverse } from "named-urls";
import queryString from 'query-string';
import routes from "../routing/routes";

const generate = (route, params = {}) => {
    return reverse(route, params);
};

/**
 * Online docs: https://www.npmjs.com/package/query-string
 */
const parseQueryString = query => {
    if (!query) return {};
    return queryString.parse(query, { decode: true, parseNumbers: true });
}

const strinifyQueryParams = params => queryString.stringify(params);

const buildViewAfterLogin = (client, activeGroup) => {
    let { viewAfterLogin } = client.settings;

    if (!viewAfterLogin) {
        if (!client.hasAgenda) {
            return reverse(routes.agenda.group);
        }
        return reverse(routes.agenda.client);
    }

    viewAfterLogin = viewAfterLogin.split('_');

    let groupId = parseInt(viewAfterLogin[1]);
    if (groupId && activeGroup && groupId !== activeGroup.id) return null;

    let redirect = null;
    switch (viewAfterLogin[0]) {
        case 'groupoverview':
            redirect = reverse(routes.agenda.group);
            break;
        case 'client':
            redirect = reverse(routes.agenda.client, { client: viewAfterLogin[2] });
            break;
        case 'subgroup':
            redirect = reverse(routes.agenda.subgroup, { subgroup: viewAfterLogin[2] });
            break;
        case 'homevisits':
            redirect = reverse(routes.homevisits.overview);
            break;
        case 'searchfreeslot':
            redirect = reverse(routes.agenda.searchfreeslot);
            break;
        case 'myagenda':
        default:
            redirect = reverse(routes.agenda.client);
            break;
    }

    return redirect;
}

export const UtilUrl = {
    generate,
    buildViewAfterLogin,

    parseQueryString,
    strinifyQueryParams
};