import React from 'react';
import { Badge } from 'reactstrap';
import { UtilColor } from '../../utils/color.util';

const BadgeCustom = props => (
    <Badge style={{ backgroundColor: props.color, color: UtilColor.contrastBlackOrWhite(props.color) }} className={props.className}>
        {props.children}
    </Badge>
)

export default BadgeCustom;