import React from 'react';
import DateField from '../../form/Elements/DateField';
import moment from 'moment';

class FilterDate extends React.Component {
    componentDidMount() {
        document.querySelector('label.' + this.props.id).addEventListener('click', this.clickListener);
    }

    componentWillUnmount() {
        document.querySelector('label.' + this.props.id).removeEventListener('click', this.clickListener);
    }

    render() {
        return (
            <label htmlFor={this.props.id} className="mr-2">
                {this.props.label}
                <DateField
                    field={{ name: this.props.id, readonly: false, data: this.props.defaultValue ? moment(this.props.defaultValue, 'DD/MM/YYYY') : null, options: {} }}
                    filter
                    onRef={ref => this._input = ref}
                    onChangeValue={(field, value) => {
                        this._input.close();
                        this.props.onChange(value)
                    }}
                />
            </label>
        );
    }

    clickListener = (e) => {
        if (e.target.classList.contains('rdtDay')) {
            this._input.close();
            this._preventOpen = true;
            return;
        }

        if (!this._preventOpen) {
            this._input.open();
        }

        this._preventOpen = false;
    }
}

export default FilterDate;