import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalBody, ModalFooter, Table, Col, Row, Alert } from 'reactstrap';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';
import { ApiAppointmentService } from '../../services/api/appointment.service';
import moment from 'moment';
import TableHead from '../table/TableHead';
import TableBody from '../table/TableBody';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';
import ReactToPrint from "react-to-print";

class ModalRepeatedAppointments extends React.Component {
    constructor(props) {
        super(props);

        this._forceUpdate = false;
        this.state = this.initModal();
    }

    initModal = () => {
        return {
            loading: true,
            modal: true,
            client: this.props.appointment ? this.props.appointment.clientId : null,
            nrCols: 5,
            appointments: [],
            styles: {
                noborder: { border: 0 }
            }
        };
    }

    componentWillReceiveProps() {
        this.setState(this.initModal());
        this._forceUpdate = true;
    }

    componentDidMount() {
        if (this.props.onModalRef) {
            this.props.onModalRef(this);
        }

        this.load();
    }

    componentDidUpdate() {
        if (this._forceUpdate) {
            this._forceUpdate = false;
            this.load();
        }
    }

    componentWillUnmount() {
        if (this.props.onModalRef) {
            this.props.onModalRef(null);
        }
    }

    render() {
        let { appointments } = this.state;

        let isOpen = 'isOpen' in this.props ? this.props.isOpen : this.state.modal;
        let conflictedAppoinmtents = this.getConflictedAppoinmtents();

        return (
            <Modal isOpen={isOpen} toggle={this.onToggle} className="modal-appointment container-fluid">
                <Row className="modal-header">
                    <Col md={12}>
                        <div className="modal-title text-bold">{t('agenda.myagenda:modal-repeated:title.' + (appointments.length > 0 && appointments[0].type ? appointments[0].type : 2))}</div>
                        <span>{t('agenda.myagenda:modal-repeated:text.' + (appointments.length > 0 && appointments[0].type ? appointments[0].type : 2))}</span>
                    </Col>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onToggle}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Row>

                <ModalBody>
                    {this.props.exceeded && (
                        <Alert color="warning">
                            <span className='fa fa-exclamation-triangle mr-1'></span>
                            {t('agenda.myagenda:modal-repeated:exceeded.message')}
                        </Alert>
                    )}
                    <Table size="lg" striped responsive>
                        <TableHead>
                            <tr>
                                <th style={{ width: '30px' }}>&nbsp;</th>
                                <th>{t('agenda.myagenda:modal-repeated:table:date')}</th>
                                <th>{t('agenda.myagenda:modal-repeated:table:time')}</th>
                                <th>{t('agenda.appointment:client')}</th>
                                <th></th>
                            </tr>
                        </TableHead>
                        <TableBody loading={this.state.loading} cols={this.state.nrCols}>
                            {appointments.map(appointment => this.renderRow(appointment))}
                        </TableBody>
                    </Table>
                </ModalBody>

                <ModalFooter className="text-center">
                    <div className="modal-buttons">
                        {conflictedAppoinmtents.length > 0 && (
                            <Button color="info" className="btn-labeled btn-e" onClick={() => this.onAllConflictClick(conflictedAppoinmtents)} disabled={conflictedAppoinmtents <= 0}>
                                <span className="btn-label"><i className={'fa fa-exclamation-triangle'} aria-hidden></i></span>
                                <div className="btn-text">{t('agenda.conflict:solve.all')}</div>
                            </Button>
                        )}
                        <ReactToPrint
                            trigger={() => {
                                return <Button color="default" className="btn-labeled btn-print" onClick={this.onPrintClick} disabled={appointments.length <= 0}>
                                    <span className="btn-label"><i className={'fa fa-print'} aria-hidden></i></span>
                                    <div className="btn-text">{t('common:Print')}</div>
                                </Button>
                            }}
                            content={() => this.componentRef}
                            pageStyle="
                                    @page { size: 80mm 50mm; }
                                    @media all {
                                        .pagebreak { display: none; }
                                    }
                                    @media print {
                                        .pagebreak { page-break-before: always; }
                                    }
                                "
                        />
                        <ComponentToPrint appointments={appointments} ref={el => this.componentRef = el} />
                    </div>
                </ModalFooter>
            </Modal >
        );
    }

    renderRow = (appointment) => {
        let classList = [];
        let styles = { borderBottom: '1px solid #dce7ed' };

        if (appointment.conflicts && appointment.conflicts.length > 0) {
            classList.push('table-danger');
            styles['borderBottom'] = 'none';
        }

        return (
            <React.Fragment key={appointment.id}>
                <tr key={appointment.id} className={classList.join(' ')}>
                    <td style={styles} className='text-center'>{appointment.repeatSequence}</td>
                    <td style={styles}>{moment(appointment.start).format('dddd DD/MM/YYYY')}</td>
                    <td style={styles} className='text-center'>{moment(appointment.start).format('HH:mm')}</td>
                    <td style={styles}>{appointment.client}</td>
                    <td style={styles} className='text-right'>
                        <Button color="info" size="sm" id={'buttonEdit' + appointment.id} onClick={() => this.onEditClick(appointment)}>
                            <i className="fas fa-pencil-alt" aria-hidden></i>
                        </Button>
                        <UncontrolledTooltip target={'buttonEdit' + appointment.id}>
                            {t('common:action-edit')}
                        </UncontrolledTooltip>

                        <Button color="danger" size="sm" id={'buttonRemove' + appointment.id} onClick={() => this.onRemoveClick(appointment)}>
                            <i className="fas fa-trash" aria-hidden></i>
                        </Button>
                        <UncontrolledTooltip target={'buttonRemove' + appointment.id}>
                            {t('common:action-remove')}
                        </UncontrolledTooltip>
                    </td>
                </tr>
                {appointment.conflicts && this.renderConflictRows(appointment)}
            </React.Fragment>
        );
    }

    renderConflictRows = (appointment) => {
        let rows = [];

        let inFuture = moment(appointment.start) >= moment();

        appointment.conflicts.forEach(conflict => {
            rows.push(
                <tr key={conflict.id} className={'table-' + conflict.severity}>
                    <td style={{ borderBottom: '1px solid #dce7ed' }} colSpan={this.state.nrCols - 1}>{conflict.description}</td>
                    <td style={{ borderBottom: '1px solid #dce7ed' }} className='text-right'>
                        <span id={'btnConflict' + conflict.id}>
                            <Button color='link' size='sm' disabled={!inFuture} onClick={() => this.onConflictClick(conflict)}>
                                {(t('agenda.conflict:solve'))}
                            </Button>
                        </span>
                        {!inFuture && (
                            <UncontrolledTooltip target={'btnConflict' + conflict.id}>
                                {(t('agenda.conflict:solve.past'))}
                            </UncontrolledTooltip>
                        )}
                    </td>
                </tr>
            )
        });
        return rows;
    }

    load = () => this.setState({ loading: true }, () => ApiAppointmentService.getByRepeatId({ repeatId: this.props.id, groupId: this.props.group.id }).then(result => this.setState({ appointments: result, loading: false })));

    getConflictedAppoinmtents = () => {
        let conflictedAppointments = [];

        this.state.appointments.forEach(appointment => {
            if (!appointment.conflicts || appointment.conflicts.length <= 0) return;
            appointment.conflicts.forEach(conflict => conflictedAppointments.push(conflict.id));
        });

        return conflictedAppointments;
    }

    onPrintClick = () => window.open(reverse(routes.appointments.print, { id: this.state.appointment.id }));

    onEditClick = (item) => {
        this.onToggleAll();

        if (item.isHomeVisit) {
            this.props.history.push(reverse(routes.homevisits.edit, { id: item.id, client: item.clientId }));
        } else {
            this.props.history.push(reverse(routes.appointments.edit, { id: item.id, client: item.clientId }));
        }
    }

    onRemoveClick = (item) => {
        this.onToggleAll();
        this.props.removeModal.fire({ id: item.id, repeatId: item.repeatId, dateFrom: item.start }, { redirectUrl: this.props.match.url + '?repeat=' + item.repeatId });
    }

    onConflictClick = (item) => {
        this.onToggleAll();
        this.props.history.push(reverse(routes.agenda.conflicts, { client: this.state.client ? this.state.client : 0, ids: [item.id] }))
    }

    onAllConflictClick = (conflictedAppoinmtents) => {
        this.onToggleAll();
        this.props.history.push(reverse(routes.agenda.conflicts, { client: this.state.client ? this.state.client : 0, ids: conflictedAppoinmtents.join('-') }));
    }

    onToggle = () => {
        if (this.props.toggle) {
            this.props.toggle();
        } else {
            this.setState({ modal: !this.state.modal });
        }
    }
    onToggleAll = () => {
        if (this.props.toggleAll) {
            this.props.toggleAll();
        } else {
            this.setState({ modal: !this.state.modal });
        }
    }
}

export default ModalRepeatedAppointments;

class ComponentToPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            style: {
                textLarge: {
                    fontSize: '1.6em'
                },
                bold: {
                    fontWeight: 'bold'
                }
            }
        };
    }

    render() {
        let { appointments } = this.props;

        let item = appointments.length > 0 ? appointments[0] : null;

        return (
            <div className="print-source">
                {item && (
                    <Table bordered>
                        <tbody style={this.state.style.textLarge}>
                            <tr>
                                <th colSpan={2} className="text-center">
                                    {t('agenda.appointment:appointmentData')}
                                </th>
                            </tr>
                            {item.group && (
                                <tr>
                                    <td>{t('common:group')}</td>
                                    <td style={this.state.style.bold}>{item.group}</td>
                                </tr>
                            )}
                            <tr>
                                <td>{t('agenda.appointment:address')}</td>
                                <td style={this.state.style.bold}>{item.address}</td>
                            </tr>
                        </tbody>
                    </Table>
                )}

                <Table bordered>
                    <tbody style={this.state.style.textLarge}>
                        <tr>
                            <th colSpan={4} className="text-center">
                                {t('patients.patient:appointments:overview')}
                            </th>
                        </tr>
                        <tr>
                            <th style={{ width: '100px' }}>&nbsp;</th>
                            <th>{t('agenda.myagenda:modal-repeated:table:date')}</th>
                            <th>{t('agenda.myagenda:modal-repeated:table:time')}</th>
                            <th>{t('agenda.appointment:client')}</th>
                        </tr>

                        {appointments.map(appointment => {
                            return (
                                <tr key={appointment.id}>
                                    <td className='text-center'>{appointment.repeatSequence}</td>
                                    <td>{moment(appointment.start).format('dddd DD/MM/YYYY')}</td>
                                    <td>{moment(appointment.start).format('HH:mm')}</td>
                                    <td>{appointment.client}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        );
    }
}