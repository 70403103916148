import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { t } from 'i18next';

class ModalAppointmentMove extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            info: null,
            data: null,
            options: {
                title: props.title ? props.title : null,
                text: props.text ? props.text : null,
                buttons: [
                    {
                        text: t('common:Cancel'),
                        color: 'default',
                        type: 'cancel'
                    },
                    {
                        text: t('common:Confirm'),
                        color: 'info',
                        type: 'confirm'
                    }
                ]
            }
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        const { open, options } = this.state;

        let modalHeader, modalBody;

        if (options.title) {
            modalHeader = (
                <ModalHeader>{options.title}</ModalHeader>
            );
        }

        if (options.text) {
            modalBody = (
                <ModalBody>{options.text}</ModalBody>
            );
        }

        return (
            <Modal isOpen={open}>
                {modalHeader}
                {modalBody}

                <ModalFooter>
                    {
                        options.buttons.map((btn, i) => {
                            return <Button key={i} color={btn.color} onClick={() => this.handle(btn.type)}>{btn.text}</Button>
                        })
                    }
                </ModalFooter>
            </Modal>
        );
    }

    fire = (info, data) => this.setState({ info: info, data: data, open: true });
    confirm = () => this.props.quickeChangeAppointmentDate(this.state.info, this.state.data);

    handle = (e) => {
        switch (e) {
            case 'confirm':
                this.confirm();
                break;
            default:
                this.cancel();
        }

        this.setState({ open: false });
    }

    cancel = () => {
        if (this.state.info.revert) {
            this.state.info.revert();
        }
    }
}

export default ModalAppointmentMove;