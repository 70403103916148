import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalBody, ModalFooter, Col, Row, Input } from 'reactstrap';
import { ApiCommonService } from '../../services/api/common.service';
import { UtilNotification } from '../../utils';

class ModalAppointmentCancel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            reason: null,
            repeated: false,
            hasError: false,
            request: {
                url: props.removeUrl,
                urlRepeated: props.removeRepeatedUrl,
                params: {},
                redirectUrl: props.redirectUrl,
                successMessage: props.successMessage,
                actionAfterRemove: props.actionAfterRemove
            }
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                <Row className="modal-header">
                    <Col md={12}>
                        <strong>Afspraak annuleren - geef een reden</strong>
                    </Col>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onToggle}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Row>

                <ModalBody className={'form-group ' + (this.state.hasError ? 'is-invalid' : '')}>
                    <Input type="textarea"
                        onChange={this.onChangeValue}
                        defaultValue={this.props.reason}
                        rows={5}
                    />
                    <div className="invalid-feedback">Gelieve een reden in te vullen</div>
                </ModalBody>

                <ModalFooter className="text-center">
                    <Button color="danger" className="btn-labeled" onClick={() => this.onRemoveClick('cancel')}>
                        <span className="btn-label"><i className="fa fa-ban" aria-hidden></i></span>
                        <div className="btn-text">{t('common:Cancel')}</div>
                    </Button>

                    {this.state.repeated && (
                        <div className="modal-buttons">
                            <Button color="danger" className="btn-labeled ml-0" onClick={() => this.onRemoveClick('cancel-upcoming')}>
                                <span className="btn-label"><i className="fa fa-ban" aria-hidden></i></span>
                                <div className="btn-text">{t('agenda.myagenda:contextMenu:cancel-repeated-upcoming')}</div>
                            </Button>
                            <Button color="danger" className="btn-labeled ml-0" onClick={() => this.onRemoveClick('cancel-all')}>
                                <span className="btn-label"><i className="fa fa-ban" aria-hidden></i></span>
                                <div className="btn-text">{t('agenda.myagenda:contextMenu:cancel-repeated-all')}</div>
                            </Button>
                        </div>
                    )}

                    <Button color="default" className="btn-labeled" onClick={this.onToggle}>
                        <span className="btn-label"><i className="fa fa-times" aria-hidden></i></span>
                        <div className="btn-text">{t('common:Close')}</div>
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }

    fire = (params, request = {}) => {
        let stateRequest = this.state.request;
        stateRequest = Object.assign(stateRequest, request);
        stateRequest = {
            ...stateRequest,
            params: params
        }

        this.setState({
            request: stateRequest,
            modal: true,
            repeated: 'repeatId' in params && params.repeatId ? true : false
        });
    };


    onChangeValue = (e) => {
        let value = e.target.value.trim();
        this.setState({ reason: value, hasError: !(!!value) });
    }

    onRemoveClick = (type) => {
        const { reason } = this.state;

        if (!reason) {
            this.setState({ hasError: true });
            return;
        }

        let { url, urlRepeated, params, redirectUrl, successMessage } = this.state.request;

        if (type !== 'cancel') {
            url = urlRepeated;
            params.id = params.repeatId;
        }
        delete params.repeatId;

        if (params.dateFrom && ['cancel', 'cancel-all'].includes(type)) {
            delete params.dateFrom;
        }

        params.reason = reason;

        ApiCommonService.removeItem(url, params)
            .catch(error => UtilNotification.error(error))
            .then(() => {
                if (redirectUrl) {
                    UtilNotification.success(successMessage);

                    this.props.history ? this.props.history.push(redirectUrl) : window.location = redirectUrl;
                } else if (this.props.onFinish) {
                    UtilNotification.toastSuccess(successMessage);
                    this.props.onFinish();
                }

                this.onToggle();
            });
    }

    onToggle = () => {
        this.setState({
            modal: !this.state.modal,
            reason: null,
            hasError: false
        });
    }
}

export default ModalAppointmentCancel;