import Base from './Base';
import BasePage from './BasePage';
import ContentWrapper from './ContentWrapper';
import Footer from './Footer';
import Header from './Header';
import Heading from './Heading';
import Sidebar from './Sidebar';

import Loader from './partials/Loader';

export {
    Base,
    BasePage,
    ContentWrapper,
    Footer,
    Header,
    Heading,
    Sidebar,

    // Partials
    Loader
};