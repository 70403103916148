import React from 'react';

const ButtonExternalLink = props => (
    <a href={props.href} target={props.target} className={'btn btn-default btn-labeled ' + props.className} rel="noopener noreferrer">
        <span className="btn-label"><i className={'fa fa-' + props.icon} aria-hidden></i></span>
        {props.children}
    </a>
)

ButtonExternalLink.defaultProps = {
    target: '_self',
    icon: 'external-link-alt'
}

export default ButtonExternalLink;
