import { API } from '../api';

const getById = (id) => API.get('client/schedule/schedule/get-schedule', { id: id }).then(response => API.isSuccess(response) ? response.data : null);
const getByClient = (id = null, groupId = null) => API.get('client/schedule/schedule/get-by-client', { client: id, group: groupId }).then(response => API.isSuccess(response) ? response.data : []);
const loadSchemaData = () => API.get('client/schedule/schedule/load-schema-data').then(response => API.isSuccess(response) ? response.data : []);

const getFreeSlotCount = (start, end, id, type) =>
    API.get('client/schedule/schedule/get-free-slot-count', { start: start, end: end, id: id, type: type })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data;
            }
        })
        .catch(() => { });

const saveBlocks = (blocks, clientId = null) => API.post('client/schedule/block/set-blocks', { data: { blocks: blocks, client: clientId } })
    .then(response => {
        if (API.isSuccess(response)) {
            return true;
        }
        throw new Error(response.data);
    });

const composeSlots = (scheduleId, data) => API.post('client/schedule/slot/compose-slots', { schedule: scheduleId, data: data }).then(response => API.isSuccess(response) ? true : false);
const getPeriodsBySchedule = (scheduleId) => API.get('client/schedule/period/get-by-schedule', { id: scheduleId }).then(response => API.isSuccess(response) ? response.data.periods : []);

const updatePriorityBreakHours = (scheduleId, data) => API.post('client/schedule/slot/update-priority-breakhours', { schedule: scheduleId, data: { slots: data } })
    .then(response => {
        if (API.isSuccess(response)) {
            return true;
        }

        throw new Error('error');
    });

const findFirstAvailableSlotForAppointment = (appointmentId) => API.get('client/schedule/schedule/find-first-available-slot', { id: appointmentId }).then(response => API.isSuccess(response) ? response.data : []);
const clone = (scheduleId) => API.get('client/schedule/schedule/duplicate-schedule', { id: scheduleId }).then(response => API.isSuccess(response) ? true : false);

export const ApiScheduleService = {
    getById,
    getByClient,
    loadSchemaData,
    getFreeSlotCount,
    saveBlocks,
    composeSlots,
    getPeriodsBySchedule,
    updatePriorityBreakHours,
    findFirstAvailableSlotForAppointment,
    clone
};