import React from 'react';
import { t } from 'i18next';
import { Alert, Button } from 'reactstrap';
import moment from 'moment';
import { ApiTagService } from '../../services/api/tag.service';
import { CSVDownload } from 'react-csv';
import { ApiPatientService } from '../../services/api/index';
import { connect } from 'react-redux';

class AlertPatientFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: props.filter,
            tags: [],
            export: {
                loading: false,
                data: []
            }
        }
    }

    componentDidMount() {
        if (this.props.onRef) this.props.onRef(this);
        this.loadTags();
    }
    componentWillUnmount() {
        if (this.props.onRef) this.props.onRef(null);
    }

    render() {
        let { filter } = this.state;

        let filters = null;
        if (filter) {
            filters = filter.filters;
        }

        return (
            <Alert color="default" className={!filter ? 'd-none' : ''}>
                {filter && (
                    <>
                        {filter.title ?
                            <strong>{t('patients.patient:filter:alert:title-existing', { title: filter.title, interpolation: { escapeValue: false } })}</strong> :
                            <strong>{t('patients.patient:filter:alert:title')}</strong>
                        }

                        <div className='float-right'>
                            <Button color="link" onClick={() => { this.props.onNewClick(); }}>
                                <span className='fa fa-plus mr-1'></span>
                                {t('patients.patient:filter:btn.new')}
                            </Button>
                            <Button color="link" onClick={() => { this.props.onSaveClick(); }}>
                                <span className='fa fa-save mr-1'></span>
                                {t('patients.patient:filter:btn.save')}
                            </Button>
                            <Button color="link" onClick={() => { this.props.onEditClick(); }}>
                                <span className='fa fa-pencil-alt mr-1'></span>
                                {t('patients.patient:filter:btn.edit')}
                            </Button>
                            <Button color="link" onClick={() => { this.props.onClearClick(null); }}>
                                <span className='fa fa-sync mr-1'></span>
                                {t('patients.patient:filter:btn.clear')}
                            </Button>
                            <Button color="link" onClick={() => this.onExportClick()} disabled={this.state.export.loading}>
                                {this.state.export.loading ?
                                    <span className="fa fa-spinner fa-spin mr-1"></span> :
                                    <span className="fa fa-download mr-1"></span>
                                }

                                {t('patients.patient:filter:btn.export')}
                            </Button>
                            {this.state.export.data.length > 0 && (<CSVDownload data={this.state.export.data} separator={';'} target="_blank" />)}
                        </div>

                        {filter.summary && <p>{filter.summary}</p>}
                        <p id='patient-filter-result'></p>
                        <hr />

                        {filters && (
                            <div className="alert-content">
                                {filters.firstName && (
                                    <div>
                                        {t('patients.patient:filter:alert:label:firstName')}:<br />
                                        <strong>{filters.firstName}</strong>
                                    </div>
                                )}
                                {filters.lastName && (
                                    <div>
                                        {t('patients.patient:filter:alert:label:lastName')}:<br />
                                        <strong>{filters.lastName}</strong>
                                    </div>
                                )}

                                {this.renderAgeLabel(filters)}

                                {filters.birthDate && (
                                    <div>
                                        {t('patients.patient:filter:alert:label:birthDate')}:<br />
                                        <strong>{moment(filters.birthDate).format('DD/MM/YYYY')}</strong>
                                    </div>
                                )}

                                {filters.gender && (
                                    <div>
                                        {t('patients.patient:filter:alert:label:gender')}:<br />
                                        <strong>{t('patients.patient:genders:' + filters.gender)}</strong>
                                    </div>
                                )}

                                {filters.hasEmail && (
                                    <div>
                                        {t('patients.patient:filter:alert:label:hasEmail')}:<br />
                                        <strong><span className='fa fa-check text-success'></span></strong>
                                    </div>
                                )}

                                {this.renderLastAppointmentLabel(filters)}
                                {this.renderStatusLabel(filter)}
                                {this.renderTagsLabel(filter)}
                            </div>
                        )}
                    </>
                )}
            </Alert>
        );
    }

    renderAgeLabel = (filter) => {
        if (!filter.ageFrom && !filter.ageTill) return null;

        return (
            <div>
                {t('patients.patient:filter:alert:label:age')}:<br />
                <strong>{t('patients.patient:filter:alert:label:fromTill', { from: filter.ageFrom ? filter.ageFrom : '-', till: filter.ageTill ? filter.ageTill : '-' })}</strong>
            </div>
        )
    }

    renderLastAppointmentLabel = (filter) => {
        if (!filter.lastAppointmentFrom && !filter.lastAppointmentTill) return null;

        return (
            <div>
                {t('patients.patient:filter:alert:label:lastAppointment')}:<br />
                <strong>{t('patients.patient:filter:alert:label:fromTill', { from: filter.lastAppointmentFrom ? moment(filter.lastAppointmentFrom).format('DD/MM/YYYYY') : '-', till: filter.lastAppointmentTill ? moment(filter.lastAppointmentTill).format('DD/MM/YYYY') : '-', interpolation: { escapeValue: false } })}</strong>
            </div>
        )
    }

    renderStatusLabel = (filter) => {
        let { statuses } = this.props;

        if (!filter.filters || !filter.filters.statuses || filter.filters.statuses.length <= 0) return null;

        return (
            <div>
                {t('patients.patient:filter:alert:label:statuses')}:<br />
                <strong>{filter.filters.statuses.map(id => statuses[id].title).join(', ')}</strong>
            </div>
        )
    }

    renderTagsLabel = (filter) => {
        let { tags } = this.state;

        if (!filter.filters || !filter.filters.tags || filter.filters.tags.length <= 0) return null;

        return (
            <div>
                {t('patients.patient:filter:alert:label:tags')}:<br />
                <strong>{filter.filters.tags.map(tag => tags[tag]).join(', ')}</strong>
            </div>
        )
    }

    updateFilters = (filter) => this.setState({ filter: filter });

    loadTags = () => {
        ApiTagService.getByGroup(this.props.group.id)
            .then(results => {
                let tags = {};
                results.forEach(tag => tags[tag.id] = tag.title);

                this.setState({ tags: tags });
            });
    }

    onExportClick = () => this.setState({ export: { loading: true, data: [] } }, () =>
        ApiPatientService.searchDt({ id: this.props.group.id, advancedFilters: JSON.stringify(this.state.filter.filters), length: 999999 })
            .then(results => {
                let exportData = [];

                let exportFields = ['firstName', 'lastName', 'birthdate', 'nationalInsuranceNumber', 'email', 'phone', 'mobile', 'street', 'number', 'box', 'postalCode', 'city', 'country', 'nationality', 'gender', 'tags', 'statuses', 'smartfield1', 'smartfield2', 'smartfield3', 'smartfield4', 'smartfield5', 'info', 'upcoming', 'firstUpcomingAppointment', 'lastAppointment', 'dateCreated', 'dateUpdated'];

                results.forEach(data => {
                    let record = {};
                    exportFields.forEach(field => {
                        let value = data[field];

                        if (value) {
                            if (['birthdate'].indexOf(field) > -1) {
                                value = moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY');
                            } else if (['firstUpcomingAppointment', 'lastAppointment', 'dateCreated', 'dateUpdated'].indexOf(field) > -1) {
                                value = moment(value).format('DD/MM/YYYY HH:mm');
                            } else if (['tags'].indexOf(field) > -1) {
                                value = value.map(tag => tag.title).join(', ');
                            } else if (['info'].indexOf(field) > -1) {
                                value = value.replace(/(?:\r\n|\r|\n)/g, '');
                            }
                        }

                        if (field === 'statuses') {
                            // Status badges
                            let counters = Object.keys(data.statusCounter);
                            let linkedStatuses = [];

                            if (counters.length > 0) {
                                counters.forEach(id => {
                                    let status = id in this.props.statuses ? this.props.statuses[id] : null;
                                    if (!status) return;
                                    linkedStatuses.push(status.title + ': ' + data.statusCounter[id].count);
                                });

                                value = linkedStatuses.join(', ');
                            }
                        }

                        record[t('patients.patient:export:label:' + field)] = value;
                    });

                    exportData.push(record);
                });

                this.setState({
                    export: {
                        loading: false,
                        data: exportData
                    }
                });
            })
    );
}

const mapStateToProps = state => ({ group: state.group, statuses: state.schemaData && state.schemaData.statuses ? state.schemaData.statuses : {} });
export default connect(mapStateToProps)(AlertPatientFilter);