import React from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { Input, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

class TranslatableTextareaField extends React.Component {
    state = {
        activeLanguage: this.props.client ? this.props.client.nativeLanguage : 'nl',
        value: this.props.field.data ? this.props.field.data : ''
    };

    componentDidMount() {
        this.addTagListener(this.props.field);
    }

    render() {
        return (
            <>
                <Nav tabs>
                    {this.props.group.languages.map(lng => (
                        <NavItem key={lng}>
                            <NavLink
                                id={'tab-' + lng} data-tab={lng} className={this.state.activeLanguage === lng ? 'active' : ''}
                                onClick={() => this.toggleTab(lng)}

                            >
                                {t('language:' + lng)}
                                <span id={'tab-error-indicator-' + lng} className="indicator text-danger float-right hidden">1</span>
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>
                <TabContent activeTab={this.state.activeLanguage} className="bg-white">
                    {this.props.group.languages.map(lng => (
                        <TabPane tabId={lng} key={lng} data-tab={lng}>
                            <Input type={this.props.field.type.replace(/translatable_/gi, '')}
                                name={this.props.field.name} id={this.props.field.name + '_' + lng}
                                onChange={this.onChangeValue}
                                defaultValue={this.state.value[lng] ? this.state.value[lng] : ''}
                                autoComplete="off" readOnly={this.props.field.readonly}
                                rows={5}
                            />
                        </TabPane>
                    ))}
                </TabContent>
            </>
        );
    }

    toggleTab = tab => this.setState({ activeLanguage: tab });

    onChangeValue = (e) => {
        let value = {
            ...this.state.value,
            [this.state.activeLanguage]: e.target.value
        }

        this.setState({ value: value }, () => this.props.onManualChangeValue(this.props.field.name, value));
    }

    addTagListener = (field) => {
        if (!field.options.tags || field.options.tags <= 0) return;

        document.addEventListener('tagInsert' + field.name, (e) => {
            let value = this.state.value;
            value[this.state.activeLanguage] = (this.state.value[this.state.activeLanguage].trim() + ' ' + e.detail.tag).trim();

            this.setState({ value: value }, () => {
                this.props.onManualChangeValue(field.name, value);

                let input = document.getElementById(field.name + '_' + this.state.activeLanguage);
                input.value = value[this.state.activeLanguage];
                input.focus();
            });
        })
    }
}


const mapStateToProps = state => ({ client: state.client.logged_in, group: state.group });

export default connect(mapStateToProps)(TranslatableTextareaField);