import React from 'react';
import { Alert } from 'reactstrap';
import { ButtonIcon } from '../buttons';

const AlertDismiss = props => (
    <Alert color="info" className="d-md-flex align-items-center">
        <div>{props.text}</div>
        <div className="ml-auto">
            <ButtonIcon color="danger" size="xs" icon="times" onClick={props.onButtonClick} />
        </div>
    </Alert>
)

export default AlertDismiss;