import React from 'react';
import { Input } from 'reactstrap';
import Axios from 'axios';

class ImageField extends React.Component {
    constructor(props) {
        super(props);

        let image = 'images/dummy.png';
        if (props.field.options.image_url) {
            image = process.env.REACT_APP_API_HOST.trim() + props.field.options.image_url
        }

        this.state = {
            image: image,
            uploadUrl: props.field.options.upload_url
        };
    }
    render() {
        const { name, readonly } = this.props.field;

        return (
            <div className="form-inline-image">
                <img className="img-thumbnail" src={this.state.image} width="75" height="75" alt="" />

                <Input type="file"
                    name={name}
                    id={name}
                    onChange={this.onChange}
                    readOnly={readonly}
                    data-buttonname="btn-secondary m-0"
                    data-classinput="form-control"
                    className="filestyle"
                />
            </div>
        );
    }

    onChange = (e) => {
        const formData = new FormData();
        // formData.append('client[image][file]', e.target.files[0]);
        formData.append('image', e.target.files[0]);

        //Axios.post(process.env.REACT_APP_API_HOST.trim() + '/' + process.env.REACT_APP_API_VERSION + '/client/client/client/update-images', formData, {
        Axios.post(this.state.uploadUrl, formData, {
            headers: {
                'Accept': 'application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
                'Content-Type': 'multipart/form-data'
            }
        }).then(result => {
            if (result.data.status === 'success') {
                let { data } = result.data;

                let image = null;
                if (data.client) {
                    image = data.client.image_small;

                    if (data.client.image) {
                        this.props.onChangeValue('image', data.client.image);
                    }
                } else if (data.group) {
                    image = data.group.logo_small;
                }

                this.setState({
                    image: image
                });
            }
        });
    }
}

export default ImageField;