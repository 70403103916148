import React from 'react';
import { t } from 'i18next';
import { Button, Modal, ModalBody, ModalFooter, Table, Row, Col } from 'reactstrap';
import Spinner from '../reactstrap/Spinner';
import { ApiPatientService } from '../../services/api/index';
import ButtonIcon from '../buttons/ButtonIcon';
import ModalPatientAppointment from './ModalPatientAppointment';
import TablePatientStatuses from '../table/TablePatientStatuses';
import moment from 'moment';
import TablePatientTask from '../table/TablePatientTask';
import { reverse } from 'named-urls';
import routes from '../../routing/routes';

class ModalPatientInfo extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this._forceUpdate = false;
        this.state = this.initModal();
    }

    initModal = () => {
        return {
            loading: true,
            patient: null,
            modal: this.props.isOpen,
            nestedModal: false
        };
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillReceiveProps() {
        this.setState(this.initModal());
        this._forceUpdate = true;
    }

    componentDidUpdate() {
        if (!this.state.patient && this._forceUpdate) {
            this._forceUpdate = false;
            this.loadData();
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }

        this._isMounted = false;
    }

    render() {
        const { loading, patient } = this.state;

        let fieldsToShow = ['firstName', 'lastName', 'nickname', 'birthdate', 'nationalInsuranceNumber', 'gender', 'email', 'phone', 'mobile', 'street', 'number', 'postalCode', 'city', 'country', 'smartfield1', 'smartfield2', 'smartfield3', 'smartfield4', 'smartfield5', 'info', 'blocked', 'blockedReason', 'tags', 'appointmentDuration'];

        return (
            <Modal size="xl" isOpen={this.props.isOpen} toggle={this.onToggle}
                className="modal-appointment container-fluid">
                <Row className="modal-header">
                    <Col lg={10} md={12}>
                        {patient ? (
                            <strong>{t('patients.patient:modal:title', { patientName: patient.fullName })}</strong>) : t('patients.patient:info')}
                    </Col>

                    {patient && (
                        <Col lg={2} md={12}>
                            <ButtonIcon icon="pencil-alt" onClick={this.onPatientEditClick} color="info"
                                tooltip={t('common:action-edit')} id={'edit' + patient.id} className="ml-1"
                            />
                            <ButtonIcon icon="calendar" onClick={this.onUpcomingAppointmentsClick}
                                tooltip={t('common:action-upcoming-appointments')}
                                id={'appointments' + patient.id} className="ml-1"
                            />
                            {this.renderAppointmentModal()}
                        </Col>
                    )}

                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={this.onToggle}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Row>
                <ModalBody>
                    <Spinner visble={loading} />
                    {!loading && patient && (
                        <Row>
                            <Col lg="6" md="12">
                                <Table className="table table-striped table-borderless table-responsive">
                                    <tbody>
                                        {fieldsToShow.map((field, i) => {
                                            let value = patient[field] ? patient[field] : null;
                                            if (value && field === 'birthdate') {
                                                value = moment(value).format('DD/MM/YYYY');
                                            }

                                            if (field === 'blocked') {
                                                value = patient.blocked ? t('common:Yes') : t('common:No');
                                            }

                                            if (field === 'blockedReason' && !patient.blocked) {
                                                return null;
                                            }

                                            if (field.includes('smartfield') > -1 && !patient[field]) {
                                                return null;
                                            }

                                            if (field === 'tags') {
                                                value = value.map(t => t.title).join(', ');
                                            }

                                            return (
                                                <tr key={i}>
                                                    <td className="modal-appointment-data-col">
                                                        <strong>{t('patients.patient:modal:' + field)}</strong></td>
                                                    <td className="wd-wide"
                                                        dangerouslySetInnerHTML={{ __html: value ? value : '-' }}></td>
                                                </tr >
                                            )
                                        })}
                                    </tbody >
                                </Table >
                            </Col >
                            <Col lg="6" md="12">
                                <div className="mb-4">
                                    <TablePatientStatuses patientId={patient.id}

                                        statusCounters={patient.status_counters} showOnEmpty
                                    />
                                </div>
                                <TablePatientTask {...this.props} patientId={patient.id} showActions={true}
                                    toggleModals={this.onToggleAll}
                                />
                            </Col >
                        </Row >
                    )}
                </ModalBody >
                <ModalFooter>
                    <Button color="info" onClick={this.onToggle}>{t('common:Close')}</Button>
                </ModalFooter>
            </Modal >
        );
    }

    fire = () => this.setState({ modal: true });
    onToggle = () => this.props.toggle();
    onToggleAll = () => this.props.toggleAll();

    loadData = () => ApiPatientService.getPatient(this.props.patientId)
        .then(result => {
            if (!this._isMounted) return;
            this.setState({ patient: result, loading: false });
        });

    onPatientEditClick = () => {
        this.onToggleAll();
        this.props.history.push(reverse(routes.patients.edit, { id: this.state.patient.id, redirect: 'test' }));
    }

    // Appointment modal
    onUpcomingAppointmentsClick = () => this.setState({ nestedModal: true });
    renderAppointmentModal = () => <ModalPatientAppointment {...this.props} toggle={this.toggleNested} onToggleAll={this.toggleAll} patient={this.state.patient} isOpen={this.state.nestedModal} showActions={true} />
    toggleNested = () => this.setState({ nestedModal: !this.state.nestedModal });

    toggleAll = () => {
        this.toggleNested();
        this.onToggle();
    }
}

export default ModalPatientInfo;