import React from 'react';

const IconText = (props) => (
    <>
        <span className={'fa fa-' + props.icon + ' mr-1 ' + props.className}></span>
        {props.text}
    </>
)

export default IconText;
