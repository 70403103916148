import React from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import moment from 'moment';

class ModalCanceledAppointment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            event: props.event
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        const { event } = this.state;
        if (!event) return null;

        let { by, date, reason } = event.extendedProps;
        date = moment(date).format('DD/MM/YYYY HH:mm:ss');

        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-canceled-appointment">
                <ModalHeader toggle={this.onToggle}>
                    {t('agenda.appointment:canceled')} <strong>{event.title}</strong>
                </ModalHeader>
                <ModalBody>
                    <Trans i18nKey="agenda.appointment:canceledBy" by={by} date={{ date }} reasn={{ reason }}>
                        Geannuleerd door <strong>{{ by }}</strong> op <strong>{{ date }}</strong> met reden <strong>{{ reason }}</strong>
                    </Trans>
                </ModalBody>
            </Modal>
        );
    }

    fire = (event) => this.setState({ modal: true, event: event });
    onToggle = () => this.setState({ modal: !this.state.modal, event: null });
}

export default ModalCanceledAppointment;