import React from 'react';
import { Editor } from '@tinymce/tinymce-react'; // Tiny MCE account --> sven@sanmax.be
import { ApiLinkService } from '../../../services/api/index';
import { UtilForm } from '../../../utils/form.util';

class EditorField extends React.Component {
    constructor(props) {
        super(props);

        let isFullyFeatured = props.fullyFeatured || ['bodyHtml'].indexOf(props.field.name) > -1 || props.field.options.fullyFeatured;

        this._ref = null;

        this.state = {
            layout: props.layout ? props.layout : {},
            isFullyFeatured: isFullyFeatured,
            loading: isFullyFeatured ? true : false,
            data: props.field.data ? props.field.data : '',
            tags: props.field.options.tags ? props.field.options.tags : {},
            links: {}
        }
    }

    componentDidMount = () => {
        let textareaEl = document.getElementById(this.props.field.name);
        if (textareaEl) textareaEl.addEventListener('input', (e) => this.onChangeValue(e.target.value));

        if (this.state.isFullyFeatured) {
            this.loadLinks();
        }

        document.addEventListener('editortSetContent', this.setContent);
    }

    componentWillUnmount() {
        document.removeEventListener('editortSetContent', this.setContent);
    }

    setContent = (e) => {
        if (!this._ref) return;
        if (e.detail.id !== this.props.field.name || !e.detail.value) return;

        this._ref.editor.setContent(e.detail.value);
    }

    onEditorChange = (content, _) => this.onChangeValue(content);
    onChangeValue = (value) => this.setState({ data: value }, () => this.props.onManualChangeValue(this.props.field.name, value));

    render() {
        if (this.state.loading) return this.renderLoader();

        return (
            <Editor id={'editor-' + this.props.field.name} name={this.props.field.name}
                //apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
                ref={ref => this._ref = ref}
                value={this.state.data}
                init={UtilForm.loadEditorConfig(this.state, this.props.field.name)}
                onEditorChange={this.onEditorChange}
            />
        );
    }

    renderLoader = () => (
        <div className='loader'>
            <span className='fa fa-spinner fa-spin fa-lg mr-2'></span>
            Loading
        </div>
    );

    loadLinks = () => ApiLinkService.getLinks(this.props.group.id).then(result => this.setState({ loading: false, links: result }));
}

export default EditorField;