import { withRouter } from 'react-router';
import axios from 'axios';
import i18next from 'i18next';
import routes from '../routing/routes';
import configureStore from '../store/store';
import { UtilApi } from '../utils/api.util';
import { APPLICATION_UPDATE, CLIENT_LOGGED_OUT } from '../store/actions';

let authToken = null;
const setToken = (token) => {
    authToken = token;
};

let sessionId = null;
const setSessionId = (id) => {
    sessionId = id;
};

const state = configureStore().getState();

const client = axios.create({
    baseURL: process.env.REACT_APP_API_HOST.trim() + '/' + process.env.REACT_APP_API_VERSION + '/',
    headers: {
        'Content-Type': 'application/json'
    }
});

const get = (url, params) => {
    return request({
        url: url,
        method: 'GET',
        params: params,
        headers: {}
    });
};

const remove = (url, params) => {
    return request({
        url: url,
        method: 'DELETE',
        params: params,
        headers: {}
    });
};

const post = (url, data, headers = {}) => {
    return request({
        url: url,
        method: 'POST',
        data: data,
        headers: headers
    });
};

const request = (requestOptions) => {
    // Cancel concurrent requests
    UtilApi.cancelRequest(requestOptions);

    // Add new request
    let nonCancelUrls = ['client/client/client/refresh-agenda', 'client/patient/patient/reset-status'];
    if (nonCancelUrls.indexOf(requestOptions.url) < 0) {
        UtilApi.addCancelToken(requestOptions)
    }

    if (!authToken) {
        authToken = state.client ? state.client.access_key : null;
    }
    client.defaults.headers.common['Authorization'] = authToken;

    if (!sessionId) {
        sessionId = state.settings.sessionId ? state.settings.sessionId : null;
    }
    client.defaults.headers.common['X-Session'] = sessionId;

    if (state.group) {
        client.defaults.headers.common['X-Group'] = state.group.id;
    }

    if (requestOptions['headers']['Content-Type']) {
        client.defaults.headers.common['Content-Type'] = requestOptions['headers']['Content-Type'];
    }

    if (requestOptions['data']) {
        requestOptions['data']['lng'] = i18next.language;
    }

    if (requestOptions['params']) {
        requestOptions['params']['lng'] = i18next.language;
    }

    client.defaults.cancelToken = UtilApi.getCancelToken(requestOptions);

    return client(requestOptions)
        .then(response => {
            // console.log(response.status, response.statusText, ':', response.data.status, response.data.data);
            return response.data;
        }).catch(error => {
            if (axios.isCancel(error)) {
                // console.log('Request aborted: ' + error);
                return false;
            } else {
                if (error.response) {
                    const { status } = error.response;

                    // console.log(status);
                    if (status === 401) {
                        UtilApi.removeAllCancelToken();
                        localStorage.removeItem('mya-app-settings');
                        configureStore().dispatch({ type: CLIENT_LOGGED_OUT });
                        setToken(null);
                        window.location = routes.auth.login;
                    } else if (status === 500) {
                        // LocalStorageService.store('FlashMessage', {type: 'error', data: 'An error occured'});
                        // window.location = routes.error;
                    }
                }
            }
        }).then(response => {
            UtilApi.removeCancelToken(requestOptions);
            return response ? response : {};
        });
};

const isSuccess = (response) => {
    return response && response.status && response.status === 'success';
}

const version = (response) => {
    if (!response || !response.version) return;

    let currentVersion = state.application ? state.application.version : null;
    configureStore().dispatch({ type: APPLICATION_UPDATE, name: 'version', value: response.version });

    if (currentVersion !== response.version) {
        window.location.reload();
    }
}

export const API = withRouter({ get, post, remove, isSuccess, setToken, setSessionId, version });