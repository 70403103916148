import React from 'react';

// CSS Loaders
import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';

const Spinner = (props) => {
    return (
        <div className={'spinner-wrapper ' + (!props.visible ? 'hidden' : '')}>
            <div className="ball-clip-rotate-multiple">
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Spinner;
