import React from 'react';
import { t } from 'i18next';
import { withLastLocation } from 'react-router-last-location';
import ButtonLink from './ButtonLink';

class ButtonBack extends React.Component {
    render() {
        let { to } = this.props;

        if (!this.props.forcePath && this.props.lastLocation) {
            to = this.props.lastLocation.pathname;
        }

        return (
            <ButtonLink icon="arrow-left" to={to} className={this.props.className} tag={this.props.tag}>
                {t(this.props.children)}
            </ButtonLink>
        );
    }
}

ButtonBack.defaultProps = {
    children: 'common:back.to.overview'
}

export default withLastLocation(ButtonBack);
