import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Notification from '../Notification';
import ModalGroupSelect from '../modals/ModalGroupSelect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import { withNamespaces } from 'react-i18next';
import { PhoneSocket } from '../app';
import { UtilAuthentication, UtilCalendar, UtilDevice } from '../../utils';
import { ModalTaskExpired } from '../modals';
import moment from 'moment';

/**
 * Wrapper element for template content
 */
class ContentWrapper extends React.Component {
    constructor(props) {
        super(props);

        UtilAuthentication.guardLastActivity(props);

        props.actions.setPageSettings({ title: null, subtitle: null });
        // props.actions.changeSetting('openAppointemtnSidebarOpen', false);

        if (props.loggedInClient && props.loggedInClient.nativeLanguage) {
            if (props.i18n.language !== props.loggedInClient.nativeLanguage) {
                props.i18n.changeLanguage(props.loggedInClient.nativeLanguage);
            }
        }

        if (!UtilCalendar.isCalendarView(this.props.match.path) && this.props.loggedInClient.settings.agendaCalendarInitialDate === 'now') {
            this.props.actions.changeSetting('selectedCalendarDate', moment());
        }
    }

    componentDidMount() {
        this.props.actions.changeSetting('isCollapsedText', this.props.settings.wasCollapsedBeforeSettings);

        if (UtilDevice.isMobile()) {
            this.props.actions.changeSetting('asideToggled', false);
        }
    }

    render() {
        return (
            <div className={'content-wrapper ' + this.props.className} >
                <ModalGroupSelect {...this.props} />
                <Notification />
                <PhoneSocket />

                {this.props.unwrap ?
                    (<div className="unwrap">{this.props.children}</div>)
                    :
                    (this.props.children)
                }

                <div id="modal-section"></div>
                <ModalTaskExpired />
            </div >
        )
    }
}

ContentWrapper.propTypes = {
    /** add element with 'unwrap' class to expand content area */
    unwrap: PropTypes.bool
}
ContentWrapper.defaultProps = {
    unwrap: false
}

const mapStateToProps = state => ({ client: state.client ? state.client.active : null, loggedInClient: state.client ? state.client.logged_in : null, settings: state.settings });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(withRouter(ContentWrapper)));
