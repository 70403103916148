import React from 'react';
import { Input } from 'reactstrap';
import NineNumberField from './Text/NineNumberField';
import CtpCodeField from './Text/CtpCodeField';
import { UtilEvent } from '../../../utils';

class TextField extends React.Component {
    state = {
        value: this.props.field.data ? this.props.field.data : ''
    };

    componentDidMount() {
        this.addTagListener(this.props.field);
    }

    render() {
        switch (this.props.field.options.codeType) {
            case 'ctpCode':
                return <CtpCodeField {...this.props} />
            case 'nineNumber':
                return <NineNumberField {...this.props} />
            default:
                return (
                    <Input type={this.props.field.type} name={this.props.field.name} id={this.props.field.name}
                        pattern={this.props.field.options.numeric ? '\\d*' : null}
                        onChange={this.onManualChangeValue}
                        defaultValue={this.state.value ? this.state.value : ''}
                        autoComplete="off"
                        readOnly={this.props.field.readonly}
                    />
                );
        }
    }

    onManualChangeValue = (e) => {
        this.setState({ value: e.target.value });
        this.props.onChangeValue(e);
        this.handleNationalInsuranceNumberInput(e.target.value);
    }

    searchCtpCode = (e) => {
        e.preventDefault();

        const { name, options } = this.props.field;

        if (!options.codeSeekerField) return;

        let messageEl = document.getElementById('ctp-message-' + name);
        if (messageEl) {
            messageEl.innerText = '';
            messageEl.classList.add('hidden');
        }

        let ninEl = document.getElementById(options.codeSeekerField);
        if (!ninEl) return;

        let ctpCodeEl = document.getElementById(name);
        if (!ctpCodeEl) return;
    }

    addTagListener = (field) => {
        if (!field.options.tags || field.options.tags <= 0) return;

        document.addEventListener('tagInsert' + field.name, (e) => {
            let newValue = (this.state.value.trim() + ' ' + e.detail.tag).trim();

            this.setState({ value: newValue }, () => {
                this.props.onManualChangeValue(field.name, newValue);

                let input = document.getElementById(field.name);
                input.value = newValue;
                input.focus();
            });
        })
    }

    handleNationalInsuranceNumberInput = (value) => {
        if (this.props.field.options.patientData !== 'nationalInsuranceNumber') return;
        UtilEvent.trigger('nationalInsuranceNumberInput', { value: value });
    }
}

export default TextField;