import moment from 'moment';
import { reverse } from 'named-urls';
import routes from '../routing/routes';
import { API } from '../services/api';
import { ApiClientService } from '../services/api/index';
import { UtilClient, UtilEvent, UtilSystem, UtilUrl } from './';

const handleAutoLogin = async (props) => {
    return await API.get(process.env.REACT_APP_API_HOST.trim() + '/' + props.match.params.path)
        .then(response => {
            if (!API.isSuccess(response)) {
                throw new Error('not-authenticated');
            }

            if (response.data.type === 'login') {
                return handleLogin(response.data, props);
            } else {
                throw new Error('not-authenticated');
            }
        })
        .catch(() => props.history.push(UtilUrl.generate(routes.auth.login)));
}

const handleLogin = (data, props) => {
    const { client, access_key, schemaData, redirect } = data;

    if (redirect === 'two-factor') {
        props.actions.clientLoggedIn({
            logged_in: client,
            active: null,
            access_key: null
        });

        return 'two-factor';
    } else {
        props.actions.notificationsLoaded(client.notifications);
        client.notifications = undefined;

        props.actions.clientLoggedIn({
            logged_in: client,
            active: client,
            access_key: access_key
        });
        props.actions.changeSetting('sessionId', UtilSystem.generateRandomId());

        props.actions.schemaDataLoaded(schemaData);
        props.actions.changeSetting('modus', 'view');
        props.actions.changeSetting('modusParams', {});
        props.actions.changeSetting('openAppointemtnSidebarOpen', false);

        let group = client.groups[0];

        let { viewAfterLogin } = client.settings;

        if (viewAfterLogin) {
            viewAfterLogin = viewAfterLogin.split('_');

            let groupId = viewAfterLogin[1];
            if (groupId) {
                client.groups.forEach(g => {
                    if (parseInt(g.id) === parseInt(groupId)) {
                        group = g;
                    }
                });
            }
        }
        props.actions.groupUpdate(group);

        let viewAfterLoginLink = UtilUrl.buildViewAfterLogin(client);

        if (viewAfterLoginLink) {
            props.history.push(viewAfterLoginLink);
        } else {
            let redirectParams = {};
            if (!client.hasAgenda && Object.entries(group).length > 0) {
                let activeClient = group.clients.filter(gClient => UtilClient.hasAccessToAgenda(client, gClient.id))[0];
                props.actions.clientSet('active', activeClient);

                redirectParams = { client: activeClient.id };
            }

            props.history.push(reverse(routes.agenda.client, redirectParams));
        }

        setTimeout(() => UtilEvent.trigger('clientLoggedin'), 500);
    }
}

const logout = (props) => {
    if (UtilClient.isLoggedIn()) {
        ApiClientService.logout();
    }

    props.actions.clientLoggedOut();
    props.actions.groupRemove();
    props.actions.schemaDataUnloaded();
    props.actions.changeSetting('sessionId', null);
    props.actions.changeSetting('lastActivity', null);
    props.actions.changeSetting('caller', {
        id: null,
        results: []
    });
    props.actions.setPatient(null);
}

const guardLastActivity = (props) => {
    if (props.settings.lastActivity) {
        let lastActivity = moment(props.settings.lastActivity);

        if (lastActivity.diff(moment(), 'hours', true) > 12) {
            let authLink = reverse(routes.auth.login);
            props.history ? props.history.push(authLink) : window.location = authLink;
        }
    }
    props.actions.changeSetting('lastActivity', moment().format('YYYY-MM-DD HH:mm:ss'));
}

export const UtilAuthentication = {
    guardLastActivity,
    handleAutoLogin,
    handleLogin,
    logout
};