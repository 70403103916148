import React from 'react';
import { Input } from 'reactstrap';

class NumberField extends React.Component {
    state = {
        value: this.props.field.data ? this.props.field.data : ''
    };

    render() {
        const { name, type, readonly, options } = this.props.field;
        const { value } = this.state;

        let inputOptions = {};
        if (type === 'number') {
            if ('min' in options) {
                inputOptions.min = options.min
            }
            if ('max' in options) {
                inputOptions.max = options.max
            }
        }

        return (
            <Input type={type}
                name={name}
                id={name}
                onChange={this.onChangeValue}
                defaultValue={value ? value : 0}
                autoComplete="off"
                readOnly={readonly}
                {...inputOptions}
            />
        );
    }

    onChangeValue = (e) => {
        let { value } = e.target;

        value = this.handleMaxAttr(e, value);
        value = this.handleMinAttr(e, value);

        e.target.value = value;

        this.props.onChangeValue(e);
    }

    handleMaxAttr = (e, value) => {
        let max = e.target.getAttribute('max');

        if (!max) return value;

        max = parseInt(max);
        value = parseInt(value);
        value = value > max ? max : value;

        return value;
    }

    handleMinAttr = (e, value) => {
        let min = e.target.getAttribute('min');

        if (!min) return value;

        min = parseInt(min);
        value = parseInt(value);
        value = value < min ? min : value;

        return value;
    }
}

export default NumberField;