import moment from 'moment';

import { TOGGLE_SETTING, CHANGE_SETTING } from './settings.actions';

const initialSettings = {
    /* Layout fixed. Scroll content only */
    isFixed: true,
    /* Sidebar collapsed */
    isCollapsed: false,
    /* Sidebar collapsed before navigation to settings */
    wasCollapsedBeforeSettings: false,
    /* Boxed layout */
    isBoxed: false,
    /* Floating sidebar */
    isFloat: false,
    /* Sidebar show menu on hover only */
    asideHover: false,
    /* Show sidebar scrollbar (dont' hide it) */
    asideScrollbar: false,
    /* Sidebar collapsed with big icons and text */
    isCollapsedText: false,
    /* Toggle for the offsidebar */
    openAppointemtnSidebarOpen: false,
    /* Toggle for the sidebar offcanvas (mobile) */
    asideToggled: false,
    /* Toggle for the sidebar user block */
    showUserBlock: true,
    /* Enables layout horizontal */
    horizontal: false,
    /* Full size layout */
    useFullLayout: false,
    /* Hide footer */
    hiddenFooter: false,
    /* Nr of agendas next to each other in group overview */
    nrAgendasRowGroupOverview: 3,
    /* Agenda modus view, move, copy, ... */
    modus: 'view',
    modusParams: {},
    lastLocation: {
        view: 'agenda',
        params: {}
    },
    sessionId: null,
    lastActivity: null,
    selectedCalendarDate: moment().format('YYYY-MM-DD'),
    phoneSocket: {
        selectedDevice: null,
        connectedPhoneNumber: null
    },
    calendar: {
        myagenda: {
            view: 'timeGridWeek'
        }
    }
};

const settingsReducer = (state = initialSettings, action) => {
    switch (action.type) {
        case TOGGLE_SETTING:
            return {
                ...state,
                [action.name]: !state[action.name]
            }
        case CHANGE_SETTING:
            return {
                ...state,
                [action.name]: action.value
            };
        default:
            return state;
    }
}

export default settingsReducer;