import React from 'react';
import { t } from 'i18next';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, Table } from 'reactstrap';
import ModalHeader from './partials/ModalHeader';
import { ApiSubgroupService } from '../../services/api/subgroup.service';
import CheckboxField from '../form/Elements/CheckboxField';
import { TableBody } from '../table';

class ModalSubgroupSearch extends React.Component {
    constructor(props) {
        super(props);

        this._timer = null;

        this.state = {
            modal: false,
            loading: false,
            search: '',
            radius: 30,
            items: [],
            selected: []
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        let itemsInRadius = this.state.items.filter(item => item.in_radius);
        let itemsNotInRadius = this.state.items.filter(item => !item.in_radius);

        return (
            <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                <ModalHeader title={t('agenda.searchfreeslot:modal.subgroup.title')} text={t('agenda.searchfreeslot:modal.subgroup.text')} className="pb-0" onClose={this.onToggle} />

                <ModalBody>
                    <div className="form-row align-items-center">
                        <div className="col-md-8">
                            <Input name="search" defaultValue={this.state.search} onChange={this.handlePostalChange} placeholder={t('agenda.searchfreeslot:modal.subgroup.input.placeholder')}
                            />
                        </div>
                        <div className="col-md-4">
                            <InputGroup>
                                <Input type="text" name="radius" id="radius" autoComplete="off" defaultValue={this.state.radius} onChange={this.handleRadiusChange} />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>Km</InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </div>
                    </div>
                </ModalBody>

                <ModalBody>
                    <Table size="sm" className="mb-0">
                        <TableBody>
                            <tr>
                                <td colSpan={3}>
                                    <strong>{t('agenda.searchfreeslot:results.in_radius', { search: this.state.search, radius: this.state.radius })}</strong>
                                </td>
                            </tr>
                        </TableBody>
                        <TableBody loading={this.state.loading}>
                            {itemsInRadius.map(item =>
                                <tr key={item.id}>
                                    <td>
                                        <CheckboxField
                                            field={{
                                                name: item.id,
                                                label: item.title,
                                                data: this.state.selected.indexOf(item.id) > -1
                                            }}
                                            data={{ [item.id]: this.state.selected.indexOf(item.id) > -1 }}
                                            onChangeValue={() => this.handleSubgroupChange(item.id)}
                                        />
                                    </td>
                                    <td className='text-muted'>{item.address}</td>
                                    <td className='text-muted'>{item.distance} km</td>
                                </tr>
                            )}
                        </TableBody>
                    </Table>

                    {itemsNotInRadius.length > 0 && itemsInRadius.length <= 0 && (
                        <Table size="sm" className="mb-0">
                            <TableBody loading={this.state.loading}>
                                <tr>
                                    <td colSpan={2}>
                                        <strong>{t('agenda.searchfreeslot:results.not_in_radius')}</strong>
                                    </td>
                                </tr>
                                {this.state.items.filter(item => !item.in_radius).map(item =>
                                    <tr key={item.id}>
                                        <td>
                                            <CheckboxField
                                                field={{
                                                    name: item.id,
                                                    label: item.title,
                                                    data: this.state.selected.indexOf(item.id) > -1
                                                }}
                                                data={{ [item.id]: this.state.selected.indexOf(item.id) > -1 }}
                                                onChangeValue={() => this.handleSubgroupChange(item.id)}
                                            />
                                        </td>
                                        <td className='text-muted'>{item.distance} km</td>
                                    </tr>
                                )}
                            </TableBody>
                        </Table>
                    )}
                </ModalBody>

                <ModalFooter>
                    <div className="modal-buttons">
                        <Button color="default" className="btn-labeled" onClick={this.onToggle}>
                            <span className="btn-label"><i className="fa fa-times" aria-hidden></i></span>
                            <div className="btn-text">{t('common:Cancel')}</div>
                        </Button>
                        <Button color="primary" className="btn-labeled" onClick={this.handleSelection}>
                            <span className="btn-label"><i className="fa fa-save" aria-hidden></i></span>
                            <div className="btn-text">{t('common:Save')}</div>
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }

    handlePostalChange = (e) => this.setState({ search: e.target.value }, () => this.load());
    handleRadiusChange = (e) => this.setState({ radius: e.target.value }, () => this.load());

    handleSubgroupChange = (id) => {
        let { selected } = this.state;

        if (selected.indexOf(id) > -1) {
            selected.splice(selected.indexOf(id), 1);
        } else {
            selected.push(id);
        }

        this.setState({ selected: selected });
    }

    handleSelection = () => {
        this.props.onConfirm(this.sanitizeSelected(this.state.selected));
        this.onToggle();
    }

    load = () => {
        if (!this.state.search || !this.state.radius) return;

        if (this._timer) clearTimeout(this._timer);
        this._timer = setTimeout(() => this.setState({ loading: true }, () =>
            ApiSubgroupService.search({ postal: this.state.search, radius: this.state.radius, group: this.props.group, subcategory: this.props.form.getData().subcategory })
                .then(result => this.setState({ items: result, loading: false }))
        ), 250);
    }

    onToggle = () => this.show(!this.state.modal, this.state.selected);
    show = (show = true, selected) => this.setState({ modal: show, selected: this.sanitizeSelected(selected) }, () => document.querySelector('input[name="search"]').focus());

    sanitizeSelected = (selected) => {
        let itemsInRadius = this.state.items.filter(item => item.in_radius);
        let itemsNotInRadius = this.state.items.filter(item => !item.in_radius);

        let visibleItems = itemsInRadius.length > 0 ? itemsInRadius : (itemsNotInRadius.length > 0 ? itemsNotInRadius : []);
        visibleItems = visibleItems.map(item => item.id);

        let newSelected = [];
        if (visibleItems.length > 0) {
            selected.forEach(s => {
                if (visibleItems.indexOf(s) > -1) newSelected.push(s);
            });
        }

        return newSelected;
    }
}

export default ModalSubgroupSearch;