import { API } from '../api';

const getByGroup = (groupId) => API.get('client/group/subgroup/get-by-group', { id: groupId }).then(response => API.isSuccess(response) ? response.data.subgroups : []);
const sort = (data) => API.post('client/group/subgroup/sort', { data: data }).then(response => API.isSuccess(response));
const search = (data) => API.post('client/group/subgroup/search', { data: data }).then(response => API.isSuccess(response) ? response.data.subgroups : []);

export const ApiSubgroupService = {
    getByGroup,
    sort,
    search
};