import React from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import DataTableTask from '../datatable/DataTableTask';

class ModalTaskOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: true
        };
    }

    componentWillReceiveProps() {
        this.setState({ open: true });
    }

    render() {
        return (
            <Modal isOpen={this.state.open} toggle={this.onToggle} className="modal-appointment container-fluid" size="xxl">
                <ModalHeader>
                    <strong>{t('tools.task:modal-title', { date: moment(this.props.date).format('DD/MM/YYYY'), interpolation: { escapeValue: false } })}</strong>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onToggle}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <DataTableTask defaultAssigend={this.props.assigned} defaultDate={this.props.date} {...this.props} />
                </ModalBody>
            </Modal>
        );
    }

    onToggle = () => this.setState({ open: !this.state.open });
}

export default ModalTaskOverview;