import { API } from '../api';

const getLinks = (groupId) => {
    return API.get('client/link/link/get-links', { groupId: groupId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.links
            }

            return {};
        })
}

export const ApiLinkService = {
    getLinks,
};