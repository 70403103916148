import React from 'react';
import { t } from 'i18next';
import ButtonLink from './ButtonLink';

const ButtonAdd = props => (
    <ButtonLink {...props} color="success" icon="plus">
        {props.children ? props.children : t('common:Add')}
    </ButtonLink>
);

export default ButtonAdd;
