import React from 'react';
import Datetime from 'react-datetime';
import MaskedInput from 'react-maskedinput';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import BirthDateField from './BirthDateField';

class DateField extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        this.state = {
            moment: props.field.data ? moment(props.field.data).format('DD/MM/YYYY') : false,
            dtOpen: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.props.onRef) {
            this.props.onRef(this);
        }

        document.addEventListener('click', this.clickListener);
        document.addEventListener('keyup', this.clickListener);

        if (this.props.field.name === 'birthdate') {
            this.bindChangeListeners();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.clickListener);
        document.removeEventListener('keyup', this.clickListener);

        if (this.props.onRef) {
            this.props.onRef(null);
        }

        this._isMounted = false;
    }

    handleChange = (value) => {
        if (!this._isMounted) return;

        this.setState({ moment: value ? value.format('DD/MM/YYYY') : false, dtOpen: false }, () =>
            this.props.onChangeValue(this.props.field.name, value.format('YYYY-MM-DD'))
        );
    }

    handleManualChange = (e) => {
        let input = e.target;
        let value = input.value ? moment(input.value, 'DD/MM/YYYY') : false;

        if (value && !value.isValid()) return;

        this.props.onChangeValue(this.props.field.name, value ? value.format('YYYY-MM-DD') : null);
    }

    render() {
        const { name } = this.props.field;

        if (this.props.field.options.patientLink === 'birthdate' || name === 'birthdate' || name === 'birthDate') {
            return <BirthDateField field={this.props.field} onChangeValue={this.props.onChangeValue} />
        }

        return (
            <Datetime
                name={name} id={name} value={this.state.moment ? this.state.moment : ''}
                dateFormat="DD/MM/YYYY" timeFormat={false}
                onChange={this.handleChange}
                open={this.state.dtOpen} closeOnSelect={true}
                input={true} renderInput={this.renderInput}
                inputProps={{ name: name, id: name, disabled: this.props.field.readonly }}
            />
        );
    }

    open = () => this.setState({ dtOpen: true });
    close = () => this.setState({ dtOpen: false });

    renderInput = (field) => (
        <MaskedInput id={field.name} name={field.name} type="text" className="form-control" disabled={field.disabled}
            mask="11/11/1111" value={this.state.moment ? this.state.moment : ''}
            onFocus={() => this.handleFocus()}
            onChange={this.handleManualChange}
        />
    );

    handleFocus = () => {
        if (this.props.filter) return;

        if (this._isMounted) {
            this.setState({ dtOpen: true })
        }
    }

    clickListener = (e) => {
        if (this.props.filter) return;

        let dtOpen = false;

        e.composedPath() && e.composedPath().forEach(path => {
            if (path.classList && path.classList.contains('rdtOpen')) {
                dtOpen = true;
            }
        });

        if (!dtOpen && this._isMounted) {
            this.setState({ dtOpen: dtOpen });
        }
    }

    bindChangeListeners = () => document.addEventListener('birthdateChanged', (e) => {
        if (!this.state.moment) {
            this.setState({ moment: moment(e.detail).format('DD/MM/YYYY') });
        }
    });
}

export default DateField;