const isMobile = () => {
    return window.innerWidth < 1024;
};

const isTouchScreen = () => {
    return (
        ('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0)
    );
}

const isTouchEnabled = () => {
    return isMobile() && isTouchScreen();
}

export const UtilDevice = {
    isMobile,
    isTouchScreen,
    isTouchEnabled
};