import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import ToggleFullscreen from './partials/ToggleFullscreen';
import HeaderRun from './scripts/Header.run'
import routes from '../../routing/routes';
import { reverse } from 'named-urls';
import { ApiClientService } from '../../services/api/client.service';
import { UtilUrl } from '../../utils/url.util';
import { ModalSupport } from '../modals';
import { UtilClient } from '../../utils/client.util';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';
import { UtilNotification } from '../../utils';

class Header extends Component {
    constructor(props) {
        super(props);

        this._modalSupport = React.createRef();

        this.state = {
            showSettingLinks: UtilClient.hasAccessToSettings(props.loggedInClient ? props.loggedInClient : null)
        }
    }

    componentDidMount() {
        HeaderRun();
        setInterval(this.watchNotificationChanges, 60 * 5 * 1000);
        // this.props.actions.changeSetting('openAppointemtnSidebarOpen', false)
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsedText');
        this.props.actions.changeSetting('wasCollapsedBeforeSettings', false);
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    onSearchSubmit = (e) => {
        e.preventDefault();

        const inputEl = document.getElementById('navbar-form-search-field');
        let value = inputEl.value;

        inputEl.value = '';

        this.props.history.push(reverse(routes.appointments.search, { searchStr: encodeURIComponent(value) }));
    }

    watchNotificationChanges = () => {
        const { notifications, group } = this.props;

        ApiClientService.getNotifications(group ? group.id : null)
            .then(result => {
                this.props.actions.notificationsLoaded(result);

                Object.entries(result).forEach(([key, value]) => {
                    if (!notifications || notifications[key] === undefined) return;
                    if (value <= notifications[key]) return;

                    let message = null;
                    let diff = value - notifications[key];
                    switch (key) {
                        case 'pendingTasks':
                            message = t('tools.task:pendingTasksNew', { count: diff });
                            break;
                        case 'reminders':
                            message = t('tools.reminder:remindersNew', { count: diff });
                            break;
                        case 'unreadMessages':
                            message = t('profile.mailbox:newMessage', { count: diff });
                            break;
                        default:
                            message = null;
                            break;
                    }

                    if (!message) return;

                    UtilNotification.toastInfo(message);
                });
            })
    }

    showSupportModal = (e) => {
        e.preventDefault();

        if (this._modalSupport) {
            this._modalSupport.onToggle();
        }
    }

    handleLogoClick = (e) => {
        e.preventDefault();
        this.props.history.push(UtilUrl.buildViewAfterLogin(this.props.clientLoggedIn, this.props.group));
    }

    render() {
        let unreadMessages = this.props.notifications ? this.props.notifications.unreadMessages : 0;
        let pendingTasks = this.props.notifications ? this.props.notifications.pendingTasks : 0;
        let newTasks = this.props.notifications ? this.props.notifications.newTasks : 0;
        let reminders = this.props.notifications ? this.props.notifications.reminders : 0;

        const badgeCount = unreadMessages + pendingTasks + newTasks + reminders;

        return (
            <header className="topnavbar-wrapper">
                {/* START Top Navbar */}
                <nav className="navbar topnavbar">
                    {/* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#" onClick={this.handleLogoClick}>
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/mya-logo-white.svg" alt="MYA logo" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src="images/mya-logo-white-collapsed.svg" alt="MYA logo" />
                            </div>
                        </a>
                    </div>
                    {/* END navbar header */}

                    {/* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"></em>
                            </a>
                            {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>

                        {/* Fullscreen (only desktops) */}
                        <li className="nav-item d-none d-md-block">
                            <ToggleFullscreen className="nav-link" />
                        </li>

                        {/* Search icon */}
                        <li className="nav-item">
                            <a className="nav-link" href="" data-search-open="">
                                <em className="icon-magnifier"></em>
                            </a>
                        </li>
                    </ul>
                    {/* END Left navbar */}

                    {/* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        {/* START stats button */}
                        {this.state.showSettingLinks && (
                            <li className="nav-item d-md-block">
                                <Link className="nav-link" to={{ pathname: UtilUrl.generate(routes.appointments.statistics.overview) }}>
                                    <em className="icon-graph"></em>
                                </Link>
                            </li>
                        )}
                        {/* END stats button */}

                        {/* START Communication center menu */}
                        {this.state.showSettingLinks && (
                            <li className="nav-item d-md-block dropdown ">
                                <Link id="badge-communication" className="nav-link" to={{ pathname: UtilUrl.generate(routes.communication.tasks.overview) }}>
                                    {badgeCount > 0 && (
                                        <>
                                            <span className="badge badge-danger">{badgeCount}</span>
                                            <UncontrolledTooltip target="badge-communication">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className='text-left'>{t('tools.task:newTasks')}:</td>
                                                            <td width="25" className='text-right'>{newTasks}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-left'>{t('tools.task:openTasks')}:</td>
                                                            <td width="25" className='text-right'>{pendingTasks}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className='text-left'>{t('profile.mailbox:unread')}:</td>
                                                            <td width="25" className='text-right'>{unreadMessages}</td>
                                                        </tr>
                                                        {/*<tr>
                                                            <td className='text-left'>{t('tools.reminder:title')}:</td>
                                                            <td width="25" className='text-right'>{reminders}</td>
                                                        </tr>*/}
                                                    </tbody>
                                                </table>
                                            </UncontrolledTooltip>
                                        </>
                                    )}
                                    <em className="icon-bell"></em>
                                </Link>
                            </li>
                        )}
                        {/* END Communication center menu */}

                        {/* START Support button */}
                        <li className="nav-item d-md-block">
                            <Link to="#" className="nav-link" onClick={this.showSupportModal}>
                                <em className="icon-info"></em>
                            </Link>
                        </li>
                        {/* END Support button */}

                        {/* START settings button */}
                        {this.state.showSettingLinks ? (
                            <li className="nav-item d-md-block">
                                <Link className="nav-link" to={{ pathname: UtilUrl.generate(this.props.clientActive && this.props.clientActive.hasAgenda ? routes.settings.schedules.overview : routes.settings.categories.overview) }}>
                                    <em className="icon-settings"></em>
                                </Link>
                            </li>
                        ) : (
                            <li className="nav-item d-md-block">
                                <Link className="nav-link" to={{ pathname: UtilUrl.generate(routes.settings.agenda.authentication) }}>
                                    <em className="icon-lock"></em>
                                </Link>
                            </li>
                        )}
                        {/* END settings menu */}

                        {/* START logout */}
                        <li className="nav-item d-md-block">
                            <Link to={{ pathname: routes.auth.login }} title="Logout" className="nav-link">
                                <em className="icon-logout"></em>
                            </Link>
                        </li>
                        {/* END logout */}
                    </ul>
                    {/* END Right Navbar */}

                    {/* START Search form */}
                    <form className="navbar-form" role="search" onSubmit={this.onSearchSubmit}>
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder={t('common:search.placeholder')} id="navbar-form-search-field" />
                            <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                        </div>
                        <button className="d-none" type="submit">Submit</button>
                    </form>

                    <ModalSupport onRef={(ref) => this._modalSupport = ref} />
                    {/* END Search form */}
                </nav>
                {/* END Top Navbar */}
            </header >
        );
    }
}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings, notifications: state.notifications, group: state.group, clientActive: state.client ? state.client.active : null, clientLoggedIn: state.client ? state.client.logged_in : null });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(Header);