import React from 'react';
import { t } from 'i18next';
import Select from 'react-select';
import { Button, Modal, ModalFooter, Col, Row, Input, Label, FormGroup, Table, Badge } from 'reactstrap';
import { ApiPaymentLinkService } from '../../services/api/payment.link.service';
import { ButtonLabel } from '../buttons';
import { AlertInfo, AlertWarning } from '../alert';
import { UtilNotification } from '../../utils';
import { ModalBody, TableBody } from '..';
import { ApiPaymentPaymentService } from '../../services/api/payment.payment.service';

class ModalPayment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            modal: true,
            type: props.type,
            id: props.id,
            items: [],
            payment: props.payment ? props.payment : {},
            email: props.payment && props.payment.email ? props.payment.email : undefined,
            select: {
                selected: null,
                options: []
            }
        };
    }

    componentDidMount() {
        if (this.props.onModalRef) {
            this.props.onModalRef(this);
        }

        this.load();
    }

    componentWillReceiveProps(newProps) {
        this.setState({ payment: newProps.payment }, () => this.load());
    }

    componentWillUnmount() {
        if (this.props.onModalRef) {
            this.props.onModalRef(null);
        }
    }

    render() {
        let { payment } = this.state;

        let showSelect = !this.state.loading && !payment.id;

        return (
            <Modal isOpen={this.state.modal} toggle={() => this.onToggle()} className="modal-appointment container-fluid">
                <Row className="modal-header">
                    <Col md={12}>
                        <div className="modal-title text-bold">{t('settings.payment:title')}</div>
                        <span>{t('settings.payment:modal:subtitle')}</span>
                    </Col>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.onToggle()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Row>

                <ModalBody loading={this.state.loading}>
                    {payment.id && this.renderPaymentDetails()}
                    {showSelect && (
                        <>
                            <FormGroup>
                                {!this.state.email && (<AlertWarning message={(t('settings.payment:modal:warning.no-email'))} />)}

                                <Label className="col-form-label" for="payment-email">{t('settings.payment:modal:input.email.label')}: *</Label>
                                <Input id="payment-email" type="text" value={this.state.email}
                                    placeholder={t('settings.payment:modal:input.email.placeholder')}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label className="col-form-label" for="select-payment-link">{t('settings.payment:modal:select.link.label')}: *</Label>
                                <Select
                                    className="form-select"
                                    id="select-payment-link"
                                    name="select-payment-link"
                                    simpleValue
                                    value={this.state.select.selected}
                                    onChange={this.handleChangeSelect}
                                    options={this.state.select.options}
                                    placeholder={t('settings.payment:modal:select.placeholder')}
                                    noOptionsMessage={() => t('settings.payment:modal:select.no-options')}
                                />
                            </FormGroup>
                        </>
                    )}
                </ModalBody>

                <ModalFooter className="text-center">
                    <div className="modal-buttons">
                        <Button color="info" onClick={() => this.onToggle()}>{t('common:Close')}</Button>
                        {payment.id && payment.status !== 'paid' && <ButtonLabel color="default" icon="paper-plane" onClick={this.handleResend}>{t('settings.payment:modal:button.resend')}</ButtonLabel>}
                        {showSelect && <ButtonLabel color="default" icon="paper-plane" onClick={this.handleSendClick} disabled={this.submitDisabled()}>{t('common:Send')}</ButtonLabel>}
                    </div>
                </ModalFooter>
            </Modal >
        );
    }

    renderPaymentDetails = () => {
        let { payment } = this.state

        return (
            <>
                <AlertInfo message={t('settings.payment:modal:info.already-sent-' + this.state.type)} />
                
                <Table striped size="sm">
                    <TableBody>
                        <tr>
                            <th width="150">{t('settings.payment:payment:table:link')}</th>
                            <td>{payment.link.title}</td>
                        </tr>
                        {payment.uuid && (
                            <tr>
                                <th width="150">ID</th>
                                <td><Badge color="outline-primary">{payment.uuid}</Badge></td>
                            </tr>
                        )}
                        <tr>
                            <th width="150">{t('settings.payment:payment:table:amount')}</th>
                            <td>&euro; {payment.amount}</td>
                        </tr>
                        <tr>
                            <th width="150">{t('settings.payment:payment:table:status')}</th>
                            <td><span className={'text-' + t('settings.payment:status:color:' + payment.status)}>{t('settings.payment:status:label:' + payment.status)}</span></td>
                        </tr>
                        <tr>
                            <th width="150">{t('settings.payment:payment:table:method')}</th>
                            <td>{payment.methodDescription ? payment.methodDescription : '-'}</td>
                        </tr>
                        <tr>
                            <th width="150">{t('settings.payment:payment:table:email')}</th>
                            <td>{payment.email}</td>
                        </tr>
                    </TableBody>
                </Table>
            </>
        );
    }

    submitDisabled = () => !this.state.email || this.state.payment.status;

    load = () => ApiPaymentLinkService.getByType(this.props.type, this.props.id).then(result => this.setState({ loading: false, modal: true, items: result.links, email: result.email }, () => this.assembleOptions()));
    onToggle = (reload = false) => this.setState({ modal: !this.state.modal }, () => this.props.onToggle(reload));
    handleChangeSelect = (selectedOption) => this.setState({ select: { ...this.state.select, selected: selectedOption ? selectedOption : { value: null, data: null } } });

    handleSendClick = () => ApiPaymentLinkService.send({ type: this.props.type, id: this.props.id, linkId: this.state.select.selected.value, email: this.state.email })
        .then(() => UtilNotification.toastSuccess(t('settings.payment:payment:create.success')))
        .then(() => this.onToggle(true))
        .catch(error => UtilNotification.toastError(error));

    handleResend = () => ApiPaymentPaymentService.sendMail(this.state.payment.id, 'invite')
        .then(() => UtilNotification.toastSuccess(t('settings.payment:payment:create.success')))
        .then(() => this.onToggle(true))
        .catch(error => UtilNotification.toastError(error));

    assembleOptions = () => this.setState({
        select: { ...this.state.select, options: this.state.items.map(item => ({ value: item.id, label: item.title })) }
    }, () => this.setState({
        select: { ...this.state.select, selected: this.state.select.options.length > 0 ? this.state.select.options[0] : null }
    }));
}

export default ModalPayment;