import React from 'react';
import { Badge, Button } from 'reactstrap';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';
import { UtilColor } from '../../utils';

const BadgeTag = props => (
    <>
        <Badge id={'badgeTooltip' + props.tag.id} pill={props.pill} color="light" className={'mr-1 px-2 py-1 ' + props.className}
            style={{ backgroundColor: props.tag.color, color: UtilColor.contrastBlackOrWhite(props.tag.color) }}
        >
            <span className="fa fa-tag mr-1"></span>
            {props.shorten && props.tag.title.length > 20 ? props.tag.title.substring(0, 20) + '...' : props.tag.title}

            {props.deletable && (
                <Button color="link" size="xs" className="p-0" onClick={props.onUnassignClick}>
                    <span className='fa fa-times ml-1'></span>
                </Button>
            )}

            {props.tag.id === 'assign' && (
                <Button color="link" size="xs" className="p-0" onClick={props.onAssignClick}>
                    <span className='fa fa-plus'></span>
                </Button>
            )}
        </Badge>
        {props.shorten && (
            <UncontrolledTooltip target={'badgeTooltip' + props.tag.id}>
                {props.tag.title}
            </UncontrolledTooltip>
        )}
    </>
)

BadgeTag.defaultProps = {
    pill: true,
    shorten: false
};

export default BadgeTag;