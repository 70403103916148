import React, { Component } from 'react';
import { connect } from 'react-redux';

class Heading extends Component {
    render() {
        const { className, title, page } = this.props;

        return (
            <div className={'heading ' + className}>
                <div className="heading-start">
                    <h1 className="heading-title">{title ? title : page.title}</h1>
                    {this.renderSubtitle(this.props)}
                </div>

                {this.renderEnd(this.props)}
            </div>
        );
    }

    renderSubtitle = () => {
        const { subtitle, page } = this.props;

        if (!page.subtitle && !subtitle) return;

        return <h2 className="heading-subtitle">{subtitle ? subtitle : page.subtitle}</h2>
    }

    renderEnd = () => {
        if (!this.props.end) return;
        
        return (
            <div className="heading-end" id="heading-end">
                {this.props.end}
            </div>
        );
    }
}

const mapStateToProps = state => ({ page: state.page });
export default connect(mapStateToProps)(Heading);