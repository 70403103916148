import {
    NOTIFICATIONS_LOADED,
    NOTIFICATIONS_MESSAGE_LOADED, NOTIFICATIONS_MESSAGE_READ, NOTIFICATIONS_MESSAGE_UNREAD, NOTIFICATIONS_MESSAGE_REMOVE,
    NOTIFICATIONS_TASK_LOADED,
    NOTIFICATIONS_REMINDERS_LOADED,
    NOTIFICATIONS_APPOINTMENT_CONFLICTS_LOADED
} from './notifications.actions';
import { CLIENT_LOGGED_OUT } from '../client/client.actions';

const initialNotifications = {
    unreadMessages: 0,
    pendingTasks: 0,
    newTasks: 0,
    reminders: 0,
    appointmentConflicts: 0,
    patientsToApprove: 0,
    patientConflicts: 0
};

const notificationsReducer = (state = initialNotifications, action) => {
    switch (action.type) {
        case NOTIFICATIONS_LOADED:
            return action.value;

        // Messages
        case NOTIFICATIONS_MESSAGE_LOADED:
            return {
                ...state,
                unreadMessages: action.value
            }
        case NOTIFICATIONS_MESSAGE_READ:
        case NOTIFICATIONS_MESSAGE_REMOVE:
            return {
                ...state,
                unreadMessages: state.unreadMessages - 1
            }
        case NOTIFICATIONS_MESSAGE_UNREAD:
            return {
                ...state,
                unreadMessages: state.unreadMessages + 1
            }


        // Tasks
        case NOTIFICATIONS_TASK_LOADED:
            return {
                ...state,
                pendingTasks: action.value
            }

        // Reminders
        case NOTIFICATIONS_REMINDERS_LOADED:
            return {
                ...state,
                reminders: action.value
            }

        // Conflicts
        case NOTIFICATIONS_APPOINTMENT_CONFLICTS_LOADED:
            return {
                ...state,
                appointmentConflicts: action.value
            }

        case CLIENT_LOGGED_OUT:
            return null;

        default:
            return state;
    }
}

export default notificationsReducer;