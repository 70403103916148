import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { t } from 'i18next';
import { ApiCommonService } from '../../services/api/common.service';
import { UtilNotification } from '../../utils/notification.util.js';

class ModalRemove extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            request: {
                url: props.removeUrl,
                params: {},
                redirectUrl: props.redirectUrl,
                successMessage: props.successMessage,
                actionAfterRemove: props.actionAfterRemove
            },
            options: {
                title: props.title ? props.title : null,
                text: props.text ? props.text : null,
                buttons: [
                    { text: t('common:Cancel'), color: 'default', type: 'cancel' },
                    { text: t('common:Confirm'), color: 'info', type: 'confirm' }
                ]
            }
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        const { title, text, buttons } = this.state.options;

        let modalHeader, modalBody;
        if (title) modalHeader = <ModalHeader>{title}</ModalHeader>;
        if (text) modalBody = <ModalBody>{text}</ModalBody>;

        return (
            <Modal isOpen={this.state.open}>
                {modalHeader}
                {modalBody}

                <ModalFooter>
                    {buttons.map(btn => <Button key={btn.type} color={btn.color} onClick={() => this.handle(btn.type)}>{btn.text}</Button>)}
                </ModalFooter>
            </Modal>
        );
    }

    fire = (params, request = {}) => {
        let stateRequest = this.state.request;
        stateRequest = Object.assign(stateRequest, request);
        stateRequest = {
            ...stateRequest,
            params: params
        }

        this.setState({
            request: stateRequest,
            open: true
        });
    };

    handle = (e) => {
        switch (e) {
            case 'confirm':
                this.removeItem();
                break;
            default:
                break;
        }

        this.setState({ open: false });
    }

    removeItem = () => {
        const { redirectUrl, successMessage, actionAfterRemove } = this.state.request;

        ApiCommonService.removeItem(this.state.request.url, this.state.request.params)
            .then(() => {
                if (actionAfterRemove) {
                    UtilNotification.toastSuccess(successMessage);
                    actionAfterRemove(this.state.request.params.id);
                } else if (redirectUrl) {
                    UtilNotification.success(successMessage);
                }
            })
            .catch(error => {
                if (actionAfterRemove) {
                    UtilNotification.toastError(error[0] ? error[0] : error);
                } else if (redirectUrl) {
                    UtilNotification.error(error);
                }
            })
            .then(() => {
                if (redirectUrl) {
                    this.props.history ? this.props.history.push(redirectUrl) : window.location = redirectUrl;
                } else if (this.props.onFinish) {
                    UtilNotification.toastSuccess(successMessage);
                    this.props.onFinish();
                }
            });
    }
}

export default ModalRemove;