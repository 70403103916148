import axios from 'axios';

let CancelToken = axios.CancelToken;
let cancelTokens = {};

const addCancelToken = (options) => {
    let key = generateKey(options);
    cancelTokens[key] = CancelToken.source();
};

const getCancelToken = (options) => {
    const key = generateKey(options);

    return cancelTokens[key] ?
        cancelTokens[key].token :
        null;
};

const cancelRequest = (options) => {
    const key = generateKey(options);

    if (cancelTokens[key]) {
        cancelTokens[key].cancel(options.url + ' cancelled');
        removeCancelToken(options);
    }
};

const removeCancelToken = (options) => {
    const key = generateKey(options);

    if (cancelTokens[key]) {
        delete cancelTokens[key];
    }
};

const removeAllCancelToken = () => {
    Object.keys(cancelTokens).forEach((key) => {
        cancelTokens[key].cancel('cancel all');
        delete cancelTokens[key];
    });
};


const generateKey = (options) => {
    const { url, params, data } = options;

    let key = url;
    if (params) {
        let allowedParams = ['client', 'clientId', 'clients', 'group', 'groupId', 'view'];
        allowedParams.forEach(allowedParam => {
            if (params[allowedParam]) {
                key += '-' + params[allowedParam];
            }
        });
    }

    if (data) {
        let allowedParams = ['id'];
        allowedParams.forEach(allowedParam => {
            if (data[allowedParam]) {
                key += '-' + data[allowedParam];
            }
        });
    }

    return key ? key.replace(/\//g, '') : key;
}

export const UtilApi = {
    addCancelToken,
    cancelRequest,
    getCancelToken,
    removeCancelToken,
    removeAllCancelToken
};

document.addEventListener('cancelRequests', (e) => {
    const { requests } = e.detail;
    if (!requests) return;

    requests.forEach((request) => {
        cancelRequest({ url: request });
    });
});