import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { t } from 'i18next';
import { UtilDate } from '../../utils/date.util.js';
import { ApiAppointmentService } from '../../services/api/appointment.service.js';
import { reverse } from 'named-urls';
import routes from '../../routing/routes.js';
import { UtilNotification } from '../../utils/notification.util.js';

class ModalAppointmentMoveFirstAvailable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            appointmentId: null,
            slot: null,
            options: {
                buttons: [
                    {
                        text: t('common:Cancel'),
                        color: 'default',
                        type: 'cancel'
                    },
                    {
                        text: t('common:Confirm'),
                        color: 'info',
                        type: 'confirm'
                    }
                ]
            }
        }
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        const { open, options, slot } = this.state;

        return (
            <Modal isOpen={open}>
                <ModalHeader>{t('agenda.conflict:moveFirstAvailable')}</ModalHeader>
                <ModalBody>{t('agenda.conflict:moveFirstAvailable.text', { date: slot ? UtilDate.create(slot.from) : '', interpolation: { escapeValue: false } })}</ModalBody>

                <ModalFooter>
                    {
                        options.buttons.map((btn, i) => {
                            return <Button key={i} color={btn.color} onClick={() => this.handle(btn.type)}>{btn.text}</Button>
                        })
                    }
                </ModalFooter>
            </Modal>
        );
    }

    fire = (id, slot) => {
        this.setState({
            appointmentId: id,
            slot: slot,
            open: true
        });
    };

    handle = (e) => {
        switch (e) {
            case 'confirm':
                this.moveItem();
                break;
            default:
                break;
        }

        this.setState({ open: false });
    }

    moveItem = () => {
        const { appointmentId, slot } = this.state;

        ApiAppointmentService.quickUpdate(appointmentId, { update: 'date', newStart: UtilDate.format(slot.from, 'YYYY-MM-DD HH:mm:ss'), newEnd: UtilDate.format(slot.till, 'YYYY-MM-DD HH:mm:ss') })
            .then(result => {
                UtilNotification.success(t('agenda.appointment:move.successfull'));
                this.props.history.push(reverse(routes.agenda.client, { client: result.appointment.clientId, date: UtilDate.format(result.appointment.start, 'YYYY-MM-DD-HH-mm') }));
            })
            .catch(error => {
                UtilNotification.error(error);
                this.props.history.push(routes.conflicts);
            });
    }
}

export default ModalAppointmentMoveFirstAvailable;