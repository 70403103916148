import { combineReducers } from 'redux';

import applicationReducer from './application/application.reducer';
import clientReducer from './client/client.reducer';
import groupReducer from './group/group.reducer';
import notificationsReducer from './notifications/notifications.reducer';
import pageReducer from './page/page.reducer';
import patientReducer from './patient/patient.reducer';
import schemaDataReducer from './schema-data/schema-data.reducer';
import settingsReducer from './settings/settings.reducer';

export default combineReducers({
    application: applicationReducer,
    client: clientReducer,
    group: groupReducer,
    notifications: notificationsReducer,
    page: pageReducer,
    patient: patientReducer,
    schemaData: schemaDataReducer,
    settings: settingsReducer
});