
const generateRandomId = (length = 32, upperCaseAllowed = false) => {
    let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    if (upperCaseAllowed) {
        characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }

    let charactersLength = characters.length;
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const UtilSystem = {
    generateRandomId
};