import React from 'react';
import TagsInput from 'react-tagsinput'

class TagField extends React.Component {
    state = {
        value: this.props.field.data ? this.props.field.data : []
    };

    render() {
        return <TagsInput value={this.state.value}
            onChange={this.onManualChangeValue}
            addOnPaste onlyUnique preventSubmit={false}
            renderTag={this.renderTag}
            validate={tag => !!tag}
            inputProps={{
                placeholder: ''
            }}
        />
    }

    renderTag = (props) => (
        <span className={props.className} key={props.key} >
            {props.getTagDisplayValue(props.tag)}
            <a className={props.classNameRemove} onClick={() => props.onRemove(props.key)}>
                <svg height="14" width="14" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
            </a>
        </span>
    );

    onManualChangeValue = (e) => this.setState({ value: e }, () => this.props.onManualChangeValue(this.props.field.name, this.state.value));
}

export default TagField;