import React from 'react';
import { Button } from 'reactstrap';
import { t } from 'i18next';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

class ButtonActionToggle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: props.active
        }
    }

    render() {
        return (
            <React.Fragment>
                <Button id={'buttonToggle' + this.props.id} size={this.props.size ? this.props.size : 'sm'}
                    className={this.props.className + ' ' + this.props.classNameTrue + ' ' + this.props.classNameFalse}
                    color={this.isActive() ? this.props.colorTrue : this.props.colorFalse}
                    onClick={this.onToggle}
                >
                    <i className={'fas fa-' + (this.isActive() ? this.props.iconTrue : this.props.iconFalse)} aria-hidden></i>
                </Button>
                <UncontrolledTooltip placement="top" target={'buttonToggle' + this.props.id}>
                    {this.isActive() ? t(this.props.textTrue) : t(this.props.textFalse)}
                </UncontrolledTooltip>
            </React.Fragment>
        )
    }

    isActive = () => !!this.state.active;

    onToggle = () => this.setState({ active: !this.state.active }, () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    });
}

ButtonActionToggle.defaultProps = {
    colorTrue: 'success',
    iconTrue: 'check',
    textTrue: 'common:De-activate',

    colorFalse: 'warning',
    iconFalse: 'times',
    textFalse: 'common:Activate'
}

export default ButtonActionToggle;