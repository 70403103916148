import { API } from '../api';

const removeItem = (url, params) => {
    return API.remove(url, params)
        .then(response => {
            if (API.isSuccess(response)) {
                return true;
            }

            throw response.data;
        });
};

const getItem = (url, params) => {
    return API.get(url, params)
        .then(response => {
            if (API.isSuccess(response)) {
                return true;
            }

            throw response.data;
        });
};

export const ApiCommonService = {
    removeItem,
    getItem
};