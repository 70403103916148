import React from 'react';
import { t } from 'i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

class ModalSupport extends React.Component {
    constructor(props) {
        super(props);

        this._forceUpdate = false;
        this.state = {
            modal: false
        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }
    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        return (
            <Modal size="md" isOpen={this.state.modal} toggle={this.onToggle}>
                <ModalHeader toggle={this.onToggle}>
                    <h4>
                        <em className="icon-info mr-2"></em>
                        {t('modal.support:title')}
                    </h4>
                </ModalHeader>
                <ModalBody>
                    <strong>{t('modal.support:helpdesk.title')}</strong><br />
                    {t('modal.support:helpdesk.tel')}: <a href="tel:003289441414">+32 (0) 89 44 14 14</a><br />
                    {t('modal.support:helpdesk.mail')}: <a href="mailto:hallo@mya-agenda.be">hallo@mya-agenda.be</a>

                    <br /><br />

                    <strong>{t('modal.support:manual.title')}</strong><br />
                    {t('modal.support:manual.text')}: <a href={t('modal.support:manual.url')} target="_blank" rel="noopener noreferrer">{t('modal.support:manual.url.text')} <em className="fa fa-external-link-alt"></em></a>

                    <br /><br />

                    <strong>{t('modal.support:teamviewer.title')}</strong><br />
                    {t('modal.support:teamviewer.text')}: <a href={t('modal.support:teamviewer.url')} target="_blank" rel="noopener noreferrer">{t('modal.support:teamviewer.url.text')} <em className="fa fa-external-link-alt"></em></a>

                    <br /><br />

                    <strong>{t('modal.support:help.title')}</strong><br />
                    {t('modal.support:help.text')}: <a href={t('modal.support:help.url')} color="link">{t('modal.support:help.url.text')}</a>
                </ModalBody>
            </Modal >
        );
    }

    onToggle = () => this.setState({ modal: !this.state.modal });
}

export default ModalSupport;