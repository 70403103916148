import { API } from '../api';

const getById = (id) => {
    return API.get('client/workspace/workspace/get-workspace', { id: id })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data;
            }

            throw new Error('error');
        });
};

const getByGroup = (groupId = null) => {
    return API.get('client/workspace/workspace/get-by-group', { groupId: groupId })
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.workspaces;
            }

            return [];
        });
};

const sort = (data) => {
    return API.post('client/workspace/workspace/sort', { data: data })
        .then(response => {
            if (response.status === 'success') {
                return true;
            }

            return false;
        });
}

export const ApiWorkspaceService = {
    getById,
    getByGroup,
    sort
};