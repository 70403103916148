import { UtilClipboard } from './clipboard.util';

const toClipboard = (item) => {
    let text = '';
    if (item.data.length <= 0) return text;
    
    item.data.forEach(data => {
        if (data.value) {
            text += data.label + ': ';
            text += data.value;
            text += '\r\n';
        }
    });

    UtilClipboard.copyTextToClipboard(text);
}

export const UtilTask = {
    toClipboard
};