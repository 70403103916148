import React from 'react';
import { t } from 'i18next';
import { Alert, Badge, Button } from 'reactstrap';
import { UtilColor } from '../../utils';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';

class AlertExceptionModus extends React.Component {
    render() {
        if (this.props.settings.modus !== 'exception') return null;

        let { borderColor, extendedProps, title } = this.props.settings.modusParams;

        return (
            <Alert color="info" className="d-md-flex align-items-center" id="alert-exception-modus">
                <div>
                    <strong>{t('settings.exception:alert:title')}</strong>
                    <p>{t('settings.exception:alert:text')}</p>
                </div>
                <div className="ml-auto">
                    {title && (
                        <Badge color="secondary" style={{ ...{ padding: '.5rem 1rem', marginRight: '2rem', fontSize: '.8rem' }, ...UtilColor.generateStyleAttributeColors(borderColor) }}>
                            {t('settings.profile:category.selected', { title: title, group: extendedProps.group })}
                        </Badge>
                    )}
                    <Button color="danger" onClick={this.props.onButtonClick}>{t('common:Cancel')}</Button>
                </div>
            </Alert>
        );
    }
}

const mapStateToProps = state => ({ settings: state.settings });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(mapStateToProps, mapDispatchToProps)(AlertExceptionModus);