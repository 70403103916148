import React from 'react';
import { t } from 'i18next';
import { Button, Input, Modal, ModalBody, ModalFooter, } from 'reactstrap';
import ModalHeader from './partials/ModalHeader';
import { ApiTagService } from '../../services/api/tag.service';
import { ApiPatientService } from '../../services/api/index';

class ModalTagAssign extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            patient: null,
            loading: true,
            tags: [],
            selected: []
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }

        this.load();
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(null);
        }
    }

    render() {
        if (this.state.tags.length <= 0 || !this.state.patient) return null;

        return (
            <>
                <Modal isOpen={this.state.modal} toggle={this.onToggle} className="modal-appointment container-fluid">
                    <ModalHeader className="border-0" onClose={this.onToggle}
                        title={t('patients.patient:tags.modal:title', { patient: this.state.patient.firstName + ' ' + this.state.patient.lastName, interpolation: { escapeValue: false } })}
                        text={t('patients.patient:tags.modal:text')}
                    />

                    <ModalBody className="border-top">
                        <Button color="link" onClick={() => this.setState({ selected: this.state.tags.map(tag => tag.id) })}>
                            {t('patients.patient:tags.modal:button.select_all')}
                        </Button>
                        <Button color="link" onClick={() => this.setState({ selected: [] })}>
                            {t('patients.patient:tags.modal:button.deselect_all')}
                        </Button>

                        <div className='card-columns-2 mt-1'>
                            {this.state.tags.map(tag => (
                                <div key={tag.id} className="checkbox c-checkbox">
                                    <label>
                                        <Input type="checkbox" value="1" disabled={this.state.selected.indexOf('all') > -1}
                                            checked={this.state.selected.indexOf(tag.id) > -1}
                                            onChange={() => this.handleSelect(tag.id)}
                                        />
                                        <span className="fa fa-check"></span> {tag.title}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </ModalBody>
                    <ModalFooter className="border-0">
                        <div className="modal-buttons">
                            <Button color="primary" className="btn-labeled" onClick={this.handleSave}>
                                <span className="btn-label"><i className="fa fa-save" aria-hidden></i></span>
                                <div className="btn-text">{t('common:Save')}</div>
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    onToggle = () => this.show(this.state.patient, !this.state.modal);
    show = (patient, doShow = true) => this.setState({ patient: patient, modal: doShow }, () => this.setSelected());

    load = () => this.setState({ loading: true }, () =>
        ApiTagService.getByGroup(this.props.group)
            .then(result => this.setState({ tags: result, loading: false }, () => this.setSelected())));

    setSelected = () => {
        if (this.state.tags.length <= 0 || !this.state.patient) return;
        this.setState({ selected: this.state.patient.tags.map(tag => tag.id) });
    }


    handleSelect = (tag) => {
        let selected = this.state.selected;
        let currentIndex = selected.indexOf(tag);

        if (currentIndex > -1) {
            selected.splice(currentIndex, 1);
        } else {
            selected.push(tag)
        }

        this.setState({ selected: selected });
    }

    handleSave = () => ApiPatientService.quickUpdate({ id: this.state.patient.id, action: 'assign-tags', value: this.state.selected })
        .then(result => this.props.onSaved(result))
        .then(() => this.onToggle());
}

export default ModalTagAssign;