import React from 'react';
import UncontrolledTooltip from '../reactstrap/UncontrolledTooltip';

const IconStack = props => (
    <>
        <span className="fa fa-fw fa-stack relative" id={'icon-stack-' + props.id}>
            <span className={"fa-stack-1x " + props.icons.first} style={{ position: 'absolute', left: '-4px', bottom: '-3px' }}></span>
            <span className={"fa-stack-1x " + props.icons.second} style={{ position: 'absolute', left: '4px', bottom: '3px' }}></span>
        </span>

        {props.tooltip && (
            <UncontrolledTooltip target={'icon-stack-' + props.id}>
                {props.tooltip}
            </UncontrolledTooltip>
        )}
    </>
);

export default IconStack;