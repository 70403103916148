import { t } from 'i18next';

const assembleAddress = (item) => {
    let address = (item.street + ' ' + (item.number ? item.number : '')).trim();
    if (item.box) {
        address += ' ' + t('patients.patient:modal:box').toLowerCase() + ' ' + item.box;
    }

    let postalPlace = item.postalCode + ' ' + item.city;
    let country = item.country;

    return (address + (postalPlace ? ', ' + postalPlace : '') + (country ? ', ' + country : '')).trim();
}

export const UtilPatient = {
    assembleAddress
};